import { useState, useEffect, useCallback, useContext } from "react"
import { navigate } from "gatsby"

import { useApp } from "./useApp"
import { useCart } from "./useCart"
import { useCore } from "./useCore"
import { useQueries } from "./useQueries"
import { useLocation } from "./useLocation"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"
import { WishlistContext } from "../providers/wishlist"

export const useWishlistContext = () => {
  const wishlistData = useContext(WishlistContext)
  return { ...wishlistData }
}

export const useWishlist = live => {
  const {
    helpers: { storage, encodeBase64, decodeBase64 },
  } = useCore()
  const {
    queries: { GET_PRODUCTS_BY_HANDLE },
  } = useQueries()
  const { countryCode } = useLocation()
  const {
    config: {
      app,
      settings: { keys, params, routes },
    },
  } = useApp()
  const { wishlist, setWishlist } = useWishlistContext()
  const { client, productNormaliser } = useShopify()

  const { addToCart, removeFromCart } = useCart()
  const [loading, setLoading] = useState(false)
  const [prevCount, setPrevCount] = useState(null)
  const [added, setAdded] = useState(false)
  const [shareUrl, setShareUrl] = useState(null)
  const [sharedWishlist, setSharedWishlist] = useState([])
  const savedWishlist = storage.get(keys?.wishlist)
  const allowed = ["id", "selectedVariant", "handle", "image", "type", "title", "dress", "info", "list"]

  const filterData = (allowed, data) =>
    Object.keys(data)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key]
        return obj
      }, {})

  const formatData = data => ({
    ...filterData(allowed, data),
  })

  useEffect(() => {
    if (savedWishlist?.length) {
      if (live) {
        setWishlist(savedWishlist)
        getWishlist(savedWishlist)
      }
    }
  }, [])

  useEffect(() => {
    const formattedWishlist = wishlist?.map(item => formatData(item))
    storage.set(keys?.wishlist, formattedWishlist || [])
  }, [wishlist.length])

  const getWishlist = async items => {
    const nonProducts = items?.filter(({ handle }) => !handle) || []
    const products = items?.filter(({ handle }) => handle) || []
    const productsWithoutData = products?.filter(({ variants }) => !variants)

    if (productsWithoutData?.length > 0) {
      const { data: wishlistLiveData } = await client.query({
        query: GET_PRODUCTS_BY_HANDLE(products?.map(product => product?.handle)),
        variables: {
          countryCode: countryCode,
          firstCollections: 0,
          firstImages: 3,
          firstMedia: 0,
          firstMetafields: 0,
          firstVariants: 100,
        },
      })

      setWishlist([
        ...nonProducts,
        ...products?.map(product => ({
          ...product,
          ...productNormaliser(wishlistLiveData[`product${product?.handle?.replace(/-/g, "")}`]),
        })),
      ])
    }
  }

  const getSharedWishlist = async () => {
  try {
    const {
      config: {
        settings: { params },
      },
    } = useApp();
    const { getUrlParameter } = useRoutes();
    const shared = getUrlParameter(params?.wishlist);

    const items = decodeBase64(shared)
      .split("|")
      .map(item => {
        const handleValue = item.split(":")[0];
        if (handleValue.includes("\u009E")) {
          console.error(`Found unexpected character in handle: ${handleValue}`);
          return null; // Use null to filter out later
        }
        return {
          handle: handleValue,
          selectedSku: item.split(":")[1],
        };
      })
      .filter(item => item !== null);

    const { data: wishlistLiveData } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE(items.map(product => product.handle)),
      variables: {
        firstCollections: 0,
        firstImages: 3,
        firstMedia: 0,
        firstMetafields: 0,
        firstVariants: 100,
      },
    });

    setSharedWishlist(
      items.map(item => ({
        ...item,
        ...productNormaliser(wishlistLiveData[`product${item.handle.replace(/-/g, "")}`]),
      }))
    );
  } catch (error) {
    console.error('Error fetching shared wishlist:', error);
    // Handle the error appropriately
  }
};

  const addToWishlist = useCallback(
    async data => {
      setLoading(true)
      const mappedData = formatData(data)
      setAdded(true)
      await setWishlist(prevState => (wishlist?.length ? [...prevState, mappedData] : [mappedData]))
      setLoading(false)
    },
    [setLoading, setWishlist, setAdded, formatData, wishlist]
  )

  const deleteFromWishlist = useCallback(
    async id => {
      setLoading(true)
      await setWishlist(prevState => prevState.filter(item => item?.id !== id))
      setLoading(false)
    },
    [setLoading, setWishlist]
  )

  const moveToCart = useCallback(
    async (id, variantId) => {
      setLoading(true)
      await addToCart(variantId, 1)
      await setWishlist(prevState => prevState.filter(item => item?.id !== id))
      if (wishlist?.length > 1) await getWishlist(wishlist.filter(item => item?.id !== id))
      setLoading(false)
    },
    [setLoading, setWishlist, getWishlist, addToCart, wishlist]
  )

  const moveToWishlist = useCallback(
    async (data, variantId) => {
      setLoading(true)
      const mappedData = formatData(data)
      await removeFromCart(variantId)
      await setWishlist(prevState => (wishlist?.length ? [...prevState, mappedData] : [mappedData]))
      setLoading(false)
    },
    [setLoading, setWishlist, addToCart, wishlist]
  )

  const existsInWishlist = useCallback(id => wishlist?.filter(item => item?.id === id).length, [wishlist])

  const updateWishlist = useCallback(
    async data => {
      setLoading(true)
      const mappedData = formatData(data)
      await setWishlist(prevState => [...prevState.filter(item => item?.id !== data?.id), mappedData])
      setLoading(false)
    },
    [setLoading, setWishlist, formatData]
  )

  const shareWishlist = useCallback(() => {
    setLoading(true)
    const string = wishlist
      ?.filter(({ handle }) => handle)
      ?.map(item => `${item.handle}:${item.selectedSku}`)
      .join("|")
    const url = `${app?.url}${routes.SAVED_SHARE}?${params.wishlist}=${encodeBase64(string)}`
    setShareUrl(url)
    setLoading(false)
  }, [encodeBase64, setLoading, setShareUrl, wishlist])

  const saveWishlist = useCallback(() => {
    setLoading(true)
    setWishlist(sharedWishlist)
    setSharedWishlist([])
    navigate(`${routes.SAVED}`, { replace: true })
    setLoading(false)
  }, [setLoading, setWishlist, setSharedWishlist, sharedWishlist, routes, navigate])

  const count = wishlist?.length || 0

  useEffect(() => {
    if (count > prevCount && prevCount !== null) {
      setAdded(true)
    }

    if (count !== 0 && count !== prevCount) {
      setPrevCount(count)
    }
  }, [count])

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false)
      }, 3000)
    }
  }, [added])

  return {
    wishlist,
    savedWishlist,
    sharedWishlist,
    getWishlist,
    getSharedWishlist,
    addToWishlist,
    deleteFromWishlist,
    updateWishlist,
    shareWishlist,
    saveWishlist,
    existsInWishlist,
    moveToCart,
    moveToWishlist,
    shareUrl,
    loading,
    count,
    added,
  }
}
