import React from "react"
import { useShopify } from "../hooks/useShopify"

interface Props {
  amount: any
  noPrefix?: boolean
  prefix?: string
}

export const Money = ({ amount, noPrefix, prefix }: Props) => {
  const { formatMoney } = useShopify()
  const money = formatMoney(amount?.amount, false)

  return (
    <>
      {prefix && !noPrefix ? (prefix ? `${prefix} ${money}` : money) : (amount?.amount ? (noPrefix ? money : money) : `—`)}
    </>
  )
}
