// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-activate-tsx": () => import("./../../../src/pages/account/activate.tsx" /* webpackChunkName: "component---src-pages-account-activate-tsx" */),
  "component---src-pages-account-addresses-tsx": () => import("./../../../src/pages/account/addresses.tsx" /* webpackChunkName: "component---src-pages-account-addresses-tsx" */),
  "component---src-pages-account-forgot-tsx": () => import("./../../../src/pages/account/forgot.tsx" /* webpackChunkName: "component---src-pages-account-forgot-tsx" */),
  "component---src-pages-account-invalid-token-tsx": () => import("./../../../src/pages/account/invalid_token.tsx" /* webpackChunkName: "component---src-pages-account-invalid-token-tsx" */),
  "component---src-pages-account-login-loading-tsx": () => import("./../../../src/pages/account/login/loading.tsx" /* webpackChunkName: "component---src-pages-account-login-loading-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-onedaypay-tsx": () => import("./../../../src/pages/account/onedaypay.tsx" /* webpackChunkName: "component---src-pages-account-onedaypay-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-preferences-tsx": () => import("./../../../src/pages/account/preferences.tsx" /* webpackChunkName: "component---src-pages-account-preferences-tsx" */),
  "component---src-pages-account-reset-tsx": () => import("./../../../src/pages/account/reset.tsx" /* webpackChunkName: "component---src-pages-account-reset-tsx" */),
  "component---src-pages-account-saved-tsx": () => import("./../../../src/pages/account/saved.tsx" /* webpackChunkName: "component---src-pages-account-saved-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-brides-tsx": () => import("./../../../src/pages/brides.tsx" /* webpackChunkName: "component---src-pages-brides-tsx" */),
  "component---src-pages-cart-login-tsx": () => import("./../../../src/pages/cart/login.tsx" /* webpackChunkName: "component---src-pages-cart-login-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-giftcard-tsx": () => import("./../../../src/pages/giftcard.tsx" /* webpackChunkName: "component---src-pages-giftcard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-password-tsx": () => import("./../../../src/pages/password.tsx" /* webpackChunkName: "component---src-pages-password-tsx" */),
  "component---src-pages-saved-share-tsx": () => import("./../../../src/pages/saved/share.tsx" /* webpackChunkName: "component---src-pages-saved-share-tsx" */),
  "component---src-pages-saved-tsx": () => import("./../../../src/pages/saved.tsx" /* webpackChunkName: "component---src-pages-saved-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-stores-tsx": () => import("./../../../src/pages/stores.tsx" /* webpackChunkName: "component---src-pages-stores-tsx" */),
  "component---src-pages-stories-tsx": () => import("./../../../src/pages/stories.tsx" /* webpackChunkName: "component---src-pages-stories-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-charge-tsx": () => import("./../../../src/templates/charge.tsx" /* webpackChunkName: "component---src-templates-charge-tsx" */),
  "component---src-templates-checkout-tsx": () => import("./../../../src/templates/checkout.tsx" /* webpackChunkName: "component---src-templates-checkout-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-flexible-tsx": () => import("./../../../src/templates/flexible.tsx" /* webpackChunkName: "component---src-templates-flexible-tsx" */),
  "component---src-templates-policy-tsx": () => import("./../../../src/templates/policy.tsx" /* webpackChunkName: "component---src-templates-policy-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-store-tsx": () => import("./../../../src/templates/store.tsx" /* webpackChunkName: "component---src-templates-store-tsx" */)
}

