import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useSettings } from "./useSettings"
import { useTemplate } from "./useTemplate"

export const useMeta = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { helpers } = useCore()
  const { languages, organisation, scripts, social, tracking } = useSettings()
  const { global } = useTemplate()

  const mapping = {
    page: helpers.schemaWebSite,
    blog: helpers.schemaBlog,
    article: helpers.schemaArticle,
    product: helpers.schemaProduct,
    collection: helpers.schemaWebSite,
    search: helpers.schemaSearch,
    store: helpers.schemaStore,
  }

  const getData = ({ breadcrumbs, data, language, routes, template, url }) =>
    helpers.schemaData({ breadcrumbs, data, global, language, organisation, routes, social, template, tracking, url }, mapping)

  const getTags = data => helpers.metaTags(data)

  const getLanguages = route =>
    languages?.hrefLangs?.map(({ _key, language, region, url }, index) => ({
      key: _key,
      primary: !index,
      href: `${url || organisation?.url}${route || ``}`,
      hrefLang: `${language}-${region}`,
      language: `${language}_${region}`,
      rel: `alternate`,
    }))

  const getSchemas = data =>
    [helpers.schemaOrg(data), helpers.schemaContent(data), helpers.schemaBreadcrumbs(data)]?.filter(
      schema => schema[`@type`] !== `Organization` || data?.url === routes?.HOMEPAGE
    )

  const getTracking = () => [
    // helpers.renderTrackingScripts(scripts?.header?.code || "", { id: "tracking", placement: "head" }),
    // helpers.renderTrackingScripts(scripts?.body?.code || "", { id: "tracking", placement: "body" }),
    // helpers.renderTrackingScripts(scripts?.footer?.code || "", { id: "tracking", placement: "foot" }),
  ]

  return {
    getData,
    getLanguages,
    getSchemas,
    getTags,
    getTracking,
  }
}
