import React, { createContext, useState, useEffect, useMemo } from "react"

export const GlobalContext = createContext({})

export const GlobalProvider = ({ children }) => {
  const [readyGroup, setReadyGroup] = useState({
    location: false,
    shopify: false,
    firebase: false,
    shop: false,
    customer: false,
    checkout: false,
  })

  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!Object.values(readyGroup).some(value => value === false)) {
      setReady(true)
    }
  }, [readyGroup])

  const providerValue = useMemo(
    () => ({
      ready,
      setReadyGroup,
    }), [ready, setReadyGroup])

  return <GlobalContext.Provider value={providerValue}>{children}</GlobalContext.Provider>
}
