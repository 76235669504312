import gql from "graphql-tag"

import { IMAGE_FRAGMENT } from "../fragments/imageFragment"
import { PRODUCT_FRAGMENT, PRODUCT_FRAGMENT_LIGHT } from "../fragments/productFragment"
import { PRODUCT_VARIANT_FRAGMENT } from "../fragments/productVariantFragment"
import { VARIANT_FRAGMENT, VARIANT_FRAGMENT_AVAILABILITY } from "../fragments/variantFragment"

export const GET_PRODUCT = gql`
  query(
    $countryCode: CountryCode!
    $handle: String!
    $parentQuery: String
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariants: String
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
    grouped: products(query: $parentQuery, first: $firstVariants) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_PRODUCT_AVAILABILITY = gql`
  query($countryCode: CountryCode!, $handle: String!) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      tags
      variants(first: 100) {
        edges {
          node {
            ...VariantFragmentAvailability
          }
        }
      }
    }
  }
  ${VARIANT_FRAGMENT_AVAILABILITY}
`

export const GET_PRODUCT_LITE = gql`
  query($countryCode: CountryCode!, $handle: String!, $firstImages: Int, $firstVariants: Int) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      id
      handle
      description
      title
      tags
      images(first: $firstImages) {
        edges {
          node {
            ...ImageFragment
          }
        }
      }
      variants(first: $firstVariants) {
        edges {
          node {
            ...VariantFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`

export const GET_PRODUCT_STANDARD = gql`
  query(
    $countryCode: CountryCode!
    $id: ID!
    $handle: String!
    $parentQuery: String
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariants: String
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
    grouped: products(query: $parentQuery, first: $firstVariants) {
      edges {
        node {
          handle
          tags
        }
      }
    }
    recommendations: productRecommendations(productId: $id) {
      handle
      tags
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_PRODUCT_COMPLETE = gql`
  query(
    $id: ID!
    $handle: String!
    $parentQuery: String
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariants: String
  ) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
    grouped: products(query: $parentQuery, first: $firstVariants) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
    recommendations: productRecommendations(productId: $id) {
      ...ProductFragmentLight
    }
  }
  ${PRODUCT_FRAGMENT}
  ${PRODUCT_FRAGMENT_LIGHT}
`

export const PRODUCT_BY_HANDLE = gql`
  query(
    $handle: String!
    $id: ID!
    $parentQuery: String
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariant: String
  ) {
    recommendations: productRecommendations(productId: $id) {
      id
      title
      handle
      tags
      description
      descriptionHtml
      availableForSale
      images(first: 1) {
        edges {
          node {
            id
            originalSrc
            altText
          }
        }
      }
      variants(first: 1) {
        edges {
          node {
            ...ProductVariantFragment
          }
        }
      }
    }
    grouped: products(query: $parentQuery, first: 10) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
    product: productByHandle(handle: $handle) {
      id
      title
      handle
      vendor
      productType
      description
      descriptionHtml
      tags
      vendor
      availableForSale
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      options {
        id
        name
        values
      }
      metafield(key: "next", namespace: "fields") {
        key
        namespace
        value
        type
      }
      variants(first: 100) {
        edges {
          node {
            ...ProductVariantFragment
          }
        }
      }
      media(first: 100) {
        edges {
          node {
            mediaContentType
            ... on MediaImage {
              image {
                originalSrc
              }
            }
            ... on Video {
              sources {
                url
                mimeType
                format
                height
                width
              }
            }
            previewImage {
              id
              altText
              originalSrc
            }
          }
        }
      }
      collections(first: 100) {
        edges {
          node {
            id
            description
            descriptionHtml
            handle
            image {
              originalSrc
              altText
            }
            title
            products(first: 10) {
              edges {
                node {
                  id
                  title
                  handle
                  tags
                  description
                  descriptionHtml
                  availableForSale
                  metafields(first: 10) {
                    edges {
                      node {
                        id
                        key
                        namespace
                        value
                        type
                      }
                    }
                  }
                  images(first: 1) {
                    edges {
                      node {
                        id
                        originalSrc
                        altText
                      }
                    }
                  }
                  variants(first: 1) {
                    edges {
                      node {
                        ...ProductVariantFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      metafields(first: 10) {
        edges {
          node {
            id
            key
            namespace
            value
            type
          }
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
`

export const GET_PRODUCTS_BY_HANDLE = handles => gql`
  query(
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariants: String
  ) {
    ${handles?.map(
      handle => `
      product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
        ...ProductFragment
      }
    `
    )}
  }
  ${PRODUCT_FRAGMENT}
`

// export const GET_PRODUCTS_BY_HANDLE = handles => gql`
//   query(
//     $countryCode: CountryCode!
//     $firstCollections: Int
//     $firstImages: Int
//     $firstMedia: Int
//     $firstMetafields: Int
//     $firstVariants: Int
//     $afterVariants: String
//   ) @inContext(country: $countryCode) {
//     ${handles?.map(
//       handle => `
//       product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
//         ...ProductFragment
//       }
//     `
//     )}
//   }
//   ${PRODUCT_FRAGMENT}
// `

export const GET_PRODUCTS_BY_HANDLE_LIGHT = handles => gql`
  query(
    $countryCode: CountryCode!
    $firstImages: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    ${handles?.map(
      handle => `
      product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
        id
        handle
        title
        tags
        images(first: $firstImages) {
          edges {
            node {
              ...ImageFragment
            }
          }
        }
        variants(first: $firstVariants) {
          edges {
            node {
              ...VariantFragment
            }
          }
        }
      }
    `
    )}
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`

export const GET_PRODUCT_IMAGES_BY_HANDLE = gql`
  query($countryCode: CountryCode!, $handle: String!, $firstImages: Int) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      images(first: $firstImages) {
        edges {
          node {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`

export const GET_POPULAR_PRODUCTS = gql`
  query($first: Int!, $firstImages: Int!, $firstMedia: Int, $firstCollections: Int!, $firstVariants: Int!, $afterVariants: String) {
    products(first: $first, sortKey: BEST_SELLING) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`

// export const GET_POPULAR_PRODUCTS = gql`
//   query (
//     $countryCode: CountryCode!
//     $first: Int!
//     $firstImages: Int!
//     $firstMedia: Int!
//     $firstMetafields: Int!
//     $firstCollections: Int!
//     $firstVariants: Int!
//     $afterVariants: String
//   ) @inContext(country: $countryCode) {
//     products(first: $first, sortKey: BEST_SELLING) {
//       edges {
//         node {
//           ...ProductFragment
//         }
//       }
//     }
//   }
//   ${PRODUCT_FRAGMENT}
// `

export const GET_PRODUCT_RECOMMENDATIONS = gql`
  query(
    $countryCode: CountryCode!
    $id: ID!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariants: String
  ) @inContext(country: $countryCode) {
    recommendations: productRecommendations(productId: $id) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_CART_RECOMMENDATIONS = ids => gql`
  query(
    $countryCode: CountryCode!
    $firstImages: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    ${ids?.map(
      (id, index) => `
      product${index}: productRecommendations(productId: "${id}") {
        id
        handle
        title
        tags
        images(first: $firstImages) {
          edges {
            node {
              ...ImageFragment
            }
          }
        }
        variants(first: $firstVariants) {
          edges {
            node {
              ...VariantFragment
            }
          }
        }
      }
    `
    )}
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`
