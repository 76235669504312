import styled from "@emotion/styled"
import tw from "twin.macro"

import { H5, P, Label } from "../../Styled/Text"

import ArrowRight from "static/icons/theme/arrow-right.svg"

export const Content = tw.div`text-center w-full max-w-40 mx-auto pt-2-4 md:pb-3-2`
export const Buttons = tw.div`w-full mt-4 flex items-center justify-between -mx-0-6`
export const Button = tw.div`w-1/2 px-0-6`
export const StyledH5 = tw(H5)`mb-0-8`
export const StyledP = tw(P)`mb-1-8`

export const Item = styled.li`
  ${tw`whitespace-no-wrap w-full md:w-1/4 border-b border-grey md:border-b-0 py-1-6 md:py-0`} ${props =>
    !props.index ? tw`border-t md:border-t-0` : ``}
`
export const SubList = styled.ul`
  ${tw`md:block mt-1-6 ml-1-8 md:ml-0`} ${props => (props.active ? tw`block` : tw`hidden`)}
`
export const StyledButton = tw(
  Label
)`flex items-center justify-start cursor-pointer md:cursor-default no-underline focus:outline-none md:pointer-events-none`
export const StyledSubLink = tw(P)`block no-underline hover:opacity-70 transition-all duration-200 focus:outline-none`
export const IconArrowRight = styled(ArrowRight)`
  ${tw`block md:hidden w-0-8 h-0-8 mr-1 transform transition-all duration-200`}
  ${props => (props.active ? tw`rotate-90` : tw`rotate-0`)}
`
export const StyledButtonText = tw.span`block`

export const FooterNav = styled.nav``
export const List = tw.ul`block md:flex list-none mt-4 md:mt-0 lg:pr-10`
