import React, { useCallback } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCustomerDetails } from "../../../hooks/useCustomer"
import { useLanguage } from "../../../hooks/useLanguage"
import { useLocation } from "../../../hooks/useLocation"
import { useSettings } from "../../../hooks/useSettings"
import { useWishlist } from "../../../hooks/useWishlist"

export const withNavigationAccount = Component => ({
  name = `NavigationAccount`,
  setActiveCart,
  activeMenu,
  setActiveMenu,
  setActiveSearch,
  location,
}) => {
  const { added } = useWishlist()
  const { storeConfig } = useLocation()
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { customer } = useCustomerDetails()
  const locales = {
    ...useLanguage(`accessibility`),
    ...useLanguage(`account`),
    ...useLanguage(`wishlist`),
  }
  const { appearance } = useSettings()

  appearance.url = storeConfig.siteLocation === "US" ? appearance.url_us || null : appearance.url_au || null

  const accountLink = customer?.email ? routes.ACCOUNT : routes.ACCOUNT_LOGIN

  const toggleSearch = useCallback(() => {
    setActiveSearch(prev => !prev)
    setActiveCart(false)
    setActiveMenu(false)
  }, [])

  const toggleMenu = useCallback(() => {
    setActiveMenu(prev => !prev)
    setActiveCart(false)
    setActiveSearch(false)
  }, [])

  const toggleCart = useCallback(() => {
    setActiveCart(prev => !prev)
    setActiveMenu(false)
    setActiveSearch(false)
  }, [])

  Component.displayName = name
  return (
    <Component
      activeMenu={activeMenu}
      accountLink={accountLink}
      added={added}
      locales={locales}
      location={location}
      routes={routes}
      toggleCart={toggleCart}
      toggleMenu={toggleMenu}
      toggleSearch={toggleSearch}
      appearance={appearance}
    />
  )
}
