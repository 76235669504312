import { useState, useCallback } from "react"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useCustomerContext } from "./useCustomer"
import { useLocation } from "./useLocation"

export const useFunctions = () => {
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { shopifyStore } = useLocation()
  const {
    config: {
      services: { functions },
    },
  } = useApp()

  const { customer, setCustomer } = useCustomerContext()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const request = useCallback(
    async (endpoint, data) => {
      setLoading(true)
      setErrors([])

      const baseEndpoint = "development" === process.env.NODE_ENV ? "/api" : functions?.endpoint

      return fetch(`${baseEndpoint}${endpoint}?shop=${shopifyStore}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(result => {
          setLoading(false)
          if (result.status == "error") {
            setErrors(result?.body)
          }
          return result
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          return error
        })
    },
    [setLoading, setErrors, shopifyStore, functions]
  )

  const customerSubscribe = useCallback(
    async email => {
      const { customer } = await request("/customer/subscribe", { email })
      return { customer }
    },
    [request]
  )

  const customerTags = useCallback(
    async tags => {
      const id = decodeBase64(customer?.id)
      const { userErrors } = await request("/customer/tags", { id, tags })
      if (!userErrors?.length) setCustomer(prevState => ({ ...prevState, tags }))
    },
    [customer, decodeBase64, request, setCustomer]
  )

  const customerMultipass = useCallback(
    async firebaseToken => {
      const { multipassToken } = await request("/customer/multipass", { firebaseToken })
      return { multipassToken }
    },
    [request]
  )

  const checkoutMultipass = useCallback(
    async (customerEmail, checkoutId, webUrl) => {
      try {
        if (customerEmail) {
          const response = await request("/checkout/multipass", { customerEmail, checkoutId, webUrl })
          const url = response?.status !== "error" && response?.includes("https://") ? response : webUrl
          window.location.replace(url)
        } else {
          window.location.replace(webUrl)
        }
      } catch (e) {
        window.location.replace(webUrl)
      }
    },
    [request]
  )

  const formSubmission = useCallback(
    async (form, data, ipData, userAgent) => {
      const id = form?.id
      const type = form?.types[data?.type?.toLowerCase()]?.title.toLowerCase()
      const subject = form?.subject || `Form submission from ${form?.title}`
      const { status, body } = await request("/form/submission", { id, type, subject, data, ipData, userAgent })
      return { status, body }
    },
    [request]
  )

  const backInStock = useCallback(
    async (email, variant) => {
      const { success } = await request("/form/product", { email, variant })
      return success
    },
    [request]
  )

  return { backInStock, customerSubscribe, customerTags, customerMultipass, checkoutMultipass, formSubmission, loading, errors }
}
