import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { Container } from "../Styled/Container"
import { I } from "../Styled/Text"

import Close from "static/icons/theme/close.svg"

export const StyledContainer = tw(Container)`pb-12`

export const CloseButton = styled.button`
  ${tw`transition-all duration-200 focus:outline-none`}
  ${props => (props.active ? tw`opacity-100 visible` : tw`opacity-0 invisible`)}
  ${props => (props.hidden ? tw`hidden` : tw`block`)}
`
export const Form = tw.form`flex items-center justify-between w-full`
export const Icon = tw(Close)`block w-2-4 h-2-4`
export const Title = tw.p`text-sm leading-loose tracking-relaxed mr-1`
export const SearchContainer = tw.div`
px-1-6 flex-1
`
export const SearchInner = styled.div`
  ${tw`flex items-center mb-2-4 border-b border-grey`}
  .SearchContainer {
    ${tw`w-full`}
    input {
      ${tw`block w-full border-0 bg-transparent`}
    }
  }
`
export const SearchLabel = tw.p`
  whitespace-no-wrap
`

export const CollectionI = tw(I)`font-display font-normal`
export const CollectionSup = tw.sup`text-xxxs ml-0-2`

export const EmptyWrapper = styled.div`
  ${tw`mb-12 h-screen`}
  ${props => (props.spacing ? tw`my-12 text-center` : ``)}
`

export const Item = styled.div`
  ${tw`w-full`} ${props =>
    props.width === "1/2"
      ? tw`md:w-1/2`
      : props.width === "1/2 md:1/2"
      ? tw`w-1/2`
      : props.width === "1/2 md:1/4"
      ? tw`w-1/2 md:w-1/4`
      : props.width === "1/3"
      ? tw`md:w-1/3`
      : props.width === "1/4"
      ? tw`md:w-1/4`
      : props.width === "1/6"
      ? tw`md:w-1/6`
      : ``}
    ${props => (props.hidden === `md` ? tw`block md:hidden` : props.hidden === `sm` ? tw`hidden md:block` : ``)}
    ${props => (props.screenWidth ? tw`pr-1-2 md:px-0-6` : props.horizontalSpacing ? tw`px-0-6` : ``)}
    ${props => (props.verticalSpacing ? tw`py-0-6` : ``)}
`
export const ItemContent = tw.div`relative`
export const ItemDetails = tw.div`w-full md:absolute md:left-0 md:bottom-0 md:pl-1-6 md:pb-1-2 pt-0-8 md:pt-0 md:opacity-0 group-hover:opacity-100 transition-all duration-200 pointer-events-none`
export const ItemImageRatio = styled.div`
  ${tw`block bg-grey-lightest`}
  ${props => (props.ratio === `0-6` ? `padding-bottom:calc(150% - 6px)` : tw`pt-150-p`)}
  ${props => (props.screenWidth ? tw`w-9/10-vw md:w-full` : ``)}
`
export const ItemImage = styled.div`
  ${tw`absolute duration-100 inset-0 cursor-pointer`}
  ${props => (props.display ? tw`opacity-100 visible` : tw`opacity-0 invisible`)}
  ${props => (props.mobile ? tw`hidden md:block` : ``)}
`
export const ItemWishlist = tw.div`absolute top-0 right-0 pr-1-2 pt-1-2 md:opacity-0 group-hover:opacity-100 transition-all duration-200`
export const ItemLink = tw(Link)`block relative`

export const LoadingRow = styled.section`
  ${tw`flex flex-wrap items-start`}
  ${props => (props.verticalSpacing ? tw`-m-0-6` : tw`-mx-0-6`)}
`
export const LoadingProducts = styled.div`
  ${tw`p-0-6 w-full`}
  ${props => (props.width === `1/2` ? tw`md:w-1/2` : ``)}
`

export const PlaceholderProduct = styled.div`
  ${tw`w-full`}
  ${props =>
    props.width === "1/2"
      ? tw`md:w-1/2`
      : props.width === "1/2 md:1/2"
      ? tw`w-1/2`
      : props.width === "1/2 md:1/4"
      ? tw`w-1/2 md:w-1/4`
      : props.width === "1/3"
      ? tw`md:w-1/3`
      : props.width === "1/4"
      ? tw`md:w-1/4`
      : ``}
    ${props => (props.hidden === `md` ? tw`block md:hidden` : props.hidden === `sm` ? tw`hidden md:block` : ``)}
    ${props => (props.screenWidth ? tw`pr-1-2 md:px-0-6` : props.horizontalSpacing ? tw`px-0-6` : ``)}
    ${props => (props.verticalSpacing ? tw`py-0-6` : ``)}
`
export const PlaceholderContent = tw.div`relative`
export const PlaceholderImage = styled.div`
  ${tw`block bg-grey-lightest animate-placeholder`} ${props => (props.ratio === `0-6` ? `padding-bottom:calc(150% - 6px)` : tw`pt-150-p`)} ${props =>
    props.screenWidth ? tw`w-9/10-vw md:w-full` : ``}
`

export const ResultsRow = styled.section`
  ${tw`flex flex-wrap items-start`} ${props => (props.verticalSpacing ? tw`-m-0-6` : tw`-mx-0-6`)}
`
export const ResultsProducts = styled.div`
  ${tw`p-0-6 w-full`} ${props => (props.width === `1/2` ? tw`md:w-1/2` : ``)}
`

export const SearchPageContainer = tw(Container)`mt-12 mb-3-8`
export const SearchWrapper = tw.div`pb-1-2`
