import React from "react"

import { withFooter } from "./withFooter"
import { Container } from "../Styled/Container"
import { NavigationFooter } from "../Navigation/Footer/NavigationFooter"
import { Subscribe } from "../Subscribe/Subscribe"
import { StoresForm } from "../Stores/Form/StoresForm"
import { Copyright } from "../Copyright/Copyright"
import {
  FooterWrapper,
  ContainerTop,
  ContainerBottom,
  Links,
  SectionTop,
  SectionBottom,
  StyledH3,
  StyledH4,
  StyledI,
  StyledTagline,
  Subscribe as StyledSubscribe,
} from "../Layout/LayoutStyles"

interface Props {
  accountHeadline: string
  accountPage: boolean
  homePage: boolean
  invertedTheme: boolean
  tagline: Array<string>
}

export const Footer = withFooter(({ accountHeadline, accountPage, homePage, invertedTheme, tagline }: Props) => (
  <FooterWrapper>
    {homePage && tagline?.length > 0 ? (
      <Container>
        <StyledTagline as={`h4`}>
          {tagline.map((tag, index) => {
            if (index > 2) return null
            return index !== 1 ? `${tag} ` : <StyledI>{` ${tag} `}</StyledI>
          })}
        </StyledTagline>

        <StyledH4 as={`p`}>
          {tagline.map((tag, index) => {
            if (index > 2) return null
            return index !== 1 ? `${tag} ` : <StyledI>{` ${tag} `}</StyledI>
          })}
        </StyledH4>
      </Container>
    ) : null}

    {homePage && tagline?.length > 2 ? (
      <Container>
        <StyledTagline as={`h4`}>
          {tagline.map((tag, index) => {
            if (index <= 2) return null
            return index < 5 ? `${tag} ` : <StyledI>{` ${tag} `}</StyledI>
          })}
        </StyledTagline>

        <StyledH4 as={`p`}>
          {tagline.map((tag, index) => {
            if (index <= 2) return null
            return index < 5 ? `${tag} ` : <StyledI>{` ${tag} `}</StyledI>
          })}
        </StyledH4>
      </Container>
    ) : null}

    {accountPage && accountHeadline ? (
      <Container>
        <StyledH3 as={`h4`}>{`${accountHeadline}`}</StyledH3>

        <StyledH4 as={`p`}>{`${accountHeadline}`}</StyledH4>
      </Container>
    ) : null}

    <SectionTop inverted={invertedTheme}>
      <ContainerTop>
        <Links>
          <NavigationFooter />
        </Links>

        <StyledSubscribe>
          <Subscribe />
          <StoresForm />
        </StyledSubscribe>
      </ContainerTop>
    </SectionTop>

    <SectionBottom inverted={invertedTheme}>
      <ContainerBottom>
        <Copyright />
      </ContainerBottom>
    </SectionBottom>
  </FooterWrapper>
))
