import React from "react"

import { SearchComponents } from "../../../../hooks/useSearch"
import { Title } from "../NavigationSearchStyles"
import { CloseButton, Icon } from "../../../Search/SearchStyles"

interface Props {
  active: boolean
  config: object
  setActive: any
  value: string
}

export const NavigationSearchForm = ({ active, config, setActive, value }: Props) => (
  <>
    <Title>Search:</Title>
    <SearchComponents.DataSearch {...config} className={`SearchContainer`} componentId={`q`} />
    <CloseButton active={active} hidden={value?.length > 1} onClick={() => setActive(!active)} type={`button`}>
      <Icon />
    </CloseButton>
  </>
)
