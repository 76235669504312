import React from "react"

import { withNavigationSearch } from "./withNavigationSearch"
import { NavigationSearchForm } from "./Form/NavigationSearchForm"
import { Wrapper, InputWrapper, StyledContainer } from "./NavigationSearchStyles"
import { NavigationSearchSuggestions } from "./Suggestions/NavigationSearchSuggestions"
import { SearchComponents } from "../../../hooks/useSearch"
import { SearchSensorHidden } from "../../Search/Sensors/SearchSensorHidden"
import { SearchSensorPublished } from "../../Search/Sensors/SearchSensorPublished"

interface Props {
  activeSearch: boolean
  inverted: boolean
  location: any
  reference: any
  setActiveSearch: any
}

export const NavigationSearch = withNavigationSearch(({ activeSearch, setActiveSearch, inverted, config, suggestionsConfig, value }: v) => (
  <Wrapper active={activeSearch} inverted={inverted}>
    <StyledContainer active={activeSearch}>
      <InputWrapper>
        <NavigationSearchForm active={activeSearch} config={config} setActive={setActiveSearch} />
      </InputWrapper>
      <SearchSensorHidden />
      <SearchSensorPublished />
      <SearchComponents.ReactiveList
        {...suggestionsConfig}
        renderNoResults={() => null}
        render={({ data }) => value?.length > 2 && data?.length > 0 && <NavigationSearchSuggestions data={data} setActive={setActiveSearch} />}
      />
    </StyledContainer>
  </Wrapper>
))
