import React from "react"

import { useSearch } from "../../../hooks/useSearch"

export const withNavigationSearch = Component => ({ name = `NavigationSearch`, location, activeSearch, setActiveSearch, inverted }) => {
  const { getSearchFormConfig, getSuggestionConfig, getQueryString } = useSearch(location)

  const config = getSearchFormConfig({
    autosuggest: true,
  })
  const value = getQueryString()
  const suggestionsConfig = {
    ...getSuggestionConfig(),
    showClear: value?.length > 1,
  }

  Component.displayName = name
  return (
    <Component
      config={config}
      suggestionsConfig={suggestionsConfig}
      activeSearch={activeSearch}
      setActiveSearch={setActiveSearch}
      inverted={inverted}
      value={value}
    />
  )
}
