import gql from "graphql-tag"

import { CHECKOUT_FRAGMENT } from "../fragments/checkoutFragment"

export const GET_CHECKOUT = gql`
  query GET_CHECKOUT($countryCode: CountryCode!, $checkoutId: ID!)
  @inContext(country: $countryCode) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`

export const GET_SHIPPING_RATES = gql`
  query GET_SHIPPING_RATES($countryCode: CountryCode!, $id: ID!)
  @inContext(country: $countryCode) {
    node(id: $id) {
      ... on Checkout {
        ...CheckoutFragment
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
              currencyCode
            }
            title
          }
        }
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`
