import React from "react"

import { useShopify } from "../../../../hooks/useShopify"

export const withNavigationSearchSuggestions = Component => ({ name = `NavigationSearchSuggestions`, data, setActive }) => {
  const { adminProductNormaliser } = useShopify()

  const items = data?.slice(0, 6)?.map(item => adminProductNormaliser(item))

  Component.displayName = name
  return <Component items={items} setActive={setActive} />
}
