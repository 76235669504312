import styled from "@emotion/styled"
import tw from "twin.macro"

import { H5, P } from "./Text"
import { Input } from "./Input"
import { Textarea } from "./Textarea"
import { PrimaryButton, TextButton } from "./Button"

export const Form = styled.form`
  ${tw`block w-full max-w-39-2 mx-auto`}
  ${props => (props.margin ? tw`mb-4` : ``)}
`
export const FormHeading = styled(H5)`
  ${props => (props.condensed ? tw`mb-1-6 text-left` : tw`mb-5-6 text-center`)}
  ${props => (props.noMargin ? tw`mb-1-6` : ``)}
`
export const FormSubSubheading = tw(P)`text-center mb-5-6`
export const FormSubheading = tw(P)`text-left mb-1-2`
export const FormLabel = tw(P)`mb-0-3 text-grey-darker`
export const FormFields = tw.div`-mx-0-6 flex flex-wrap`
export const FormFluidField = styled.div`
  ${tw`px-0-6 w-full`}
  ${props => (props.width === `1/2` ? tw`md:w-1/2` : ``)}
`
export const FormDropdown = styled.div`
  ${props => (!props.collapsed ? tw`mb-1-2` : ``)}
  ${props => (props.disabled ? tw`opacity-50 pointer-events-none` : ``)}
`
export const FormInput = styled(Input)`
  ${props => (!props.collapsed ? tw`mb-1-2` : ``)}
  ${props => (props.lightBorder ? tw`border-grey focus:border-dark` : ``)}
`
export const FormInputHidden = styled(Input)`
  ${props => (!props.collapsed ? tw`mb-1-2 invisible` : `invisible`)}
`
export const FormTextarea = tw(Textarea)`mb-0 h-auto`
export const FormInputs = tw.div`w-full flex items-center mb-1-2 -mx-0-6`
export const FormInputItem = tw.div`flex-1 px-0-6`
export const FormInputWrapper = tw.div`w-full flex items-center relative mb-1-2`
export const FormInputInner = styled.div`
  ${tw`w-full flex flex-col `}
  ${props => (props.first ? tw`mr-1` : ``)}
  ${props => (props.last ? tw`ml-1` : ``)}
`
export const FormInputButton = tw(TextButton)`absolute right-0-8 text-grey-darker text-xs`
export const FormInputWithButton = tw(Input)`pr-4`
export const FormCheckboxItem = tw.div`mr-3-2 mb-1-2`
export const FormButtonWrapper = styled.div`
  ${tw`mt-2-4 md:px-4`}
  ${({ withLoginSpacing }) => withLoginSpacing && tw`md:mt-10`}
  ${({ withGuestSpacing }) => withGuestSpacing && tw`md:mt-13-2`}
`
export const FormButton = tw(PrimaryButton)`block w-full`
export const FormLinkWrapper = tw.div`-mt-0-4`
export const FormLinkInnerWrapper = styled.div`
  ${tw`relative flex justify-start cursor-pointer select-none`}
  ${props => (props.centered ? tw`items-center` : tw`items-start py-1-1`)}
`
export const FormLink = tw(P)`text-xs hover:underline -mt-0-4`
export const FormLinkButton = styled.button`
  ${tw`inline underline hover:no-underline focus:outline-none`}
  ${({ center }) => center && tw`block text-center w-full`}
`
export const FormLinkButtonSpacing = tw(P)`mt-1-2`
export const FormLinkButtonWrapper = tw.div`-mt-1-4`
export const FormError = styled(P)`
  ${tw`text-error`}
  ${props => (!props.condensed ? tw`mt-2-4` : ``)}
`
export const FormSuccess = tw(P)`mt-2-4 text-success`
export const FormLargeNote = tw(P)`-mt-1-2 text-xs`
export const FormNote = tw(P)`mt-2-4 text-xs text-grey-darker text-center leading-loosest`

export const BlockLabel = tw.label`block`
export const SmallText = tw.span`text-sm`
export const SmallFormInput = tw.input`my-1 mr-1`
