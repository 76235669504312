import React from "react"

import { withImage } from "./withImage"
import { ImageRatio, ImageWrapper, StyledImage, StyledGatsbyImage } from "./ImageStyles"

interface Props {
  alt: string
  cover: boolean
  fluid: any
  gradient: boolean
  inline: boolean
  maxV: boolean
  onClick: any
  preload: any
  ratio: any
  src: any
  srcSets: any
}

export const Image = withImage(
  ({ alt = ``, cover = false, fluid, gradient, inline = false, maxV, onClick = null, ratio = null, src = ``, srcSets }: Props) =>
    fluid ? (
      <StyledGatsbyImage alt={alt} onClick={onClick} fluid={{ ...fluid, aspectRatio: ratio ? ratio : fluid.aspectRatio }} gradient={gradient} />
    ) : ratio && typeof ratio === `string` ? (
      <ImageWrapper onClick={onClick}>
        <ImageRatio ratio={ratio} />
        {src ? <StyledImage alt={alt} cover={`true`} src={src} srcSet={srcSets} /> : null}
      </ImageWrapper>
    ) : (
      <StyledImage alt={alt} cover={cover ? `true` : null} inline={inline ? `true` : null} maxV={maxV} onClick={onClick} src={src} srcSet={srcSets || ``} />
    )
)
