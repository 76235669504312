import React from "react"

import { SearchComponents } from "../../../hooks/useSearch"

export const SearchSensorHidden = () => (
  <SearchComponents.ReactiveComponent
    componentId="HiddenSensor"
    customQuery={() => ({
      query: {
        bool: {
          must_not: [
            {
              term: {
                tags: "hidden",
              },
            },
          ],
        },
      },
    })}
  />
)
