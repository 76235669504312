import gql from "graphql-tag"

import { IMAGE_FRAGMENT } from "./imageFragment"

export const MEDIA_IMAGE_FRAGMENT = gql`
  fragment MediaImageFragment on MediaImage {
    alt
    id
    image {
      ...ImageFragment
    }
  }
  ${IMAGE_FRAGMENT}
`
export const MEDIA_VIDEO_FRAGMENT = gql`
  fragment MediaVideoFragment on Video {
    alt
    id
    previewImage {
      ...ImageFragment
    }
    sources {
      format
      height
      mimeType
      url
      width
    }
  }
  ${IMAGE_FRAGMENT}
`

export const MEDIA_EXTERNAL_VIDEO_FRAGMENT = gql`
  fragment MediaExternalVideoFragment on ExternalVideo {
    alt
    embeddedUrl
    id
    previewImage {
      ...ImageFragment
    }
  }
  ${IMAGE_FRAGMENT}
`

export const MEDIA_MODEL_FRAGMENT = gql`
  fragment MediaModel3dFragment on Model3d {
    alt
    id
    previewImage {
      ...ImageFragment
    }
    sources {
      filesize
      format
      mimeType
      url
    }
  }
  ${IMAGE_FRAGMENT}
`