import React, { useState } from "react"

import { useApp } from "../../hooks/useApp"
import { useCustomerDetails } from "../../hooks/useCustomer"
import { useFunctions } from "../../hooks/useFunctions"
import { useLanguage } from "../../hooks/useLanguage"

export const withSubscribe = Component => ({ name = `Subscribe` }) => {
  const { invertedTheme } = useApp()
  const { customer } = useCustomerDetails()
  const { customerSubscribe, loading } = useFunctions()
  const [activePopup, setActivePopup] = useState(false)
  const [email, setEmail] = useState(``)

  const locales = {
    ...useLanguage(`form`),
    ...useLanguage(`account`),
  }

  const handleChange = event => setEmail(event.target.value)

  const handleSubmit = async event => {
    event.preventDefault()
    const email = !event.target.elements ? "" : !event.target.elements.email ? "" : event.target.elements.email.value

    await customerSubscribe(email)
    setActivePopup(true)
    setEmail(``)
  }

  Component.displayName = name
  return !customer?.email ? (
    <Component
      activePopup={activePopup}
      customer={customer}
      email={email}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      invertedTheme={invertedTheme}
      locales={locales}
      loading={loading}
      setActivePopup={setActivePopup}
    />
  ) : null
}
