import React from "react"

import { Product } from "../../../../types"

import { withSearchItem } from "./withSearchItem"
import { Image } from "../../Image/Image"
import { Money } from "../../Money"
import { WishlistButton } from "../../Wishlist/Button/WishlistButton"
import { P } from "../../Styled/Text"
import { Item, ItemContent, ItemDetails, ItemWishlist, ItemLink, ItemImageRatio, ItemImage } from "../SearchStyles"
import { CompareAtPrice } from "../../Product/Form/ProductFormStyles"

interface Props {
  collection: any
  defaultVariant?: any
  displayHover?: boolean
  handleHover: any
  hidden: boolean
  itemRef: any
  product: Product
  primaryImage: any
  onClick?: any
  ratio: string
  screenWidth: boolean
  secondaryImage: any
  horizontalSpacing?: boolean
  url: string
  verticalSpacing?: boolean
  wholesale: boolean
  width: string
}

export const SearchItem = withSearchItem(
  ({
    collection,
    defaultVariant,
    displayHover,
    hidden,
    handleHover,
    horizontalSpacing = true,
    itemRef,
    onClick = () => null,
    primaryImage,
    product,
    ratio,
    screenWidth,
    secondaryImage,
    url,
    verticalSpacing = false,
    wholesale,
    width,
  }: Props) => (
    <Item
      hidden={hidden}
      screenWidth={screenWidth}
      horizontalSpacing={horizontalSpacing}
      verticalSpacing={verticalSpacing}
      onClick={onClick}
      width={width}
      className="group"
    >
      <ItemContent ref={itemRef} onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
        <ItemLink state={{ collection }} to={url} draggable={false}>
          <ItemImageRatio ratio={ratio} screenWidth={screenWidth} />
          <ItemImage display={!displayHover}>
            {product.images?.length > 0 && (
              <Image alt={primaryImage?.alt || `${product?.title} product image`} cover src={primaryImage?.originalSrc || primaryImage} />
            )}
          </ItemImage>
          {secondaryImage && (
            <ItemImage display={displayHover}>
              <Image alt={secondaryImage?.alt || `${product?.title} product image`} cover src={secondaryImage.src || secondaryImage} />
            </ItemImage>
          )}
        </ItemLink>

        <ItemDetails>
          <P>{product.vendor}</P>
          <P>{product.title}</P>
          <P>
            <Money amount={defaultVariant?.priceV2} prefix={`WS`} />
            {defaultVariant?.compareAtPriceV2?.amount && defaultVariant?.compareAtPriceV2?.amount !== 0 ? (
              <CompareAtPrice compareAtPrice strikethrough={!wholesale}>
                <Money amount={defaultVariant?.compareAtPriceV2} prefix={`RRP`} />
              </CompareAtPrice>
            ) : null}
          </P>
        </ItemDetails>

        <ItemWishlist>
          <WishlistButton item={product} />
        </ItemWishlist>
      </ItemContent>
    </Item>
  )
)
