import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useCustomerDetails } from "../../../hooks/useCustomer"
import { useLanguage } from "../../../hooks/useLanguage"
import { useWishlist } from "../../../hooks/useWishlist"

export const withWishlistCount = Component => ({ name = `WishlistCount` }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { customer } = useCustomerDetails()
  const { count } = useWishlist(true)
  const locales = useLanguage(`wishlist`)

  Component.displayName = name
  return <Component count={count} customer={customer} locales={locales} routes={routes} />
}
