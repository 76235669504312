import React, { useEffect } from "react"

import { useLanguage } from "../../../hooks/useLanguage"
import { useScrollHidden } from "../../../hooks/useScroll"

export const withPopup = Component => ({ name = `Popup`, active, children, noClose, setActive, transparent, width }) => {
  const locales = useLanguage(`accessibility`)

  useEffect(() => {
    useScrollHidden(active)
  }, [active])

  Component.displayName = name
  return (
    <Component active={active} locales={locales} noClose={noClose} setActive={setActive} transparent={transparent} width={width}>
      {children}
    </Component>
  )
}
