import gql from "graphql-tag"

import { IMAGE_FRAGMENT } from "./imageFragment"
import { PRICE_FRAGMENT } from "./priceFragment"

export const VARIANT_FRAGMENT = gql`
  fragment VariantFragment on ProductVariant {
    id
    sku
    title
    weight
    weightUnit
    availableForSale
    currentlyNotInStock
    quantityAvailable
    requiresShipping
    selectedOptions {
      name
      value
    }
    image {
      ...ImageFragment
    }
    ...PriceFragment
    priceV2 {
      amount
      currencyCode
    }
  }
  ${IMAGE_FRAGMENT}
  ${PRICE_FRAGMENT}
`

export const VARIANT_FRAGMENT_AVAILABILITY = gql`
  fragment VariantFragmentAvailability on ProductVariant {
    id
    sku
    availableForSale
    currentlyNotInStock
    quantityAvailable
    ...PriceFragment
  }
  ${PRICE_FRAGMENT}
`

