import React from "react"

import { useCheckout } from "../../../hooks/useCheckout"
import { useLanguage } from "../../../hooks/useLanguage"

export const withCartDrawerCount = Component => ({ name = `CartDrawerCount` }) => {
  const { count } = useCheckout()
  const locales = useLanguage(`cart`)

  Component.displayName = name
  return <Component count={count} locales={locales} />
}
