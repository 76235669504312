import React, { useCallback, useState } from "react"

import { useLanguage } from "../../../hooks/useLanguage"
import { useCheckout, useCheckoutContext } from "../../../hooks/useCheckout"
import { useCustomerDetails } from "../../../hooks/useCustomer"
import { useFunctions } from "../../../hooks/useFunctions"

export const withCartFormCheckout = Component => ({ name = `CartFormCheckout`, setActiveCart, ...props }) => {
  const locales = useLanguage(`cart`)
  const { checkoutUrl } = useCheckout()
  const { checkout } = useCheckoutContext()
  const { customer, customerAccessToken } = useCustomerDetails()
  const { checkoutMultipass } = useFunctions()
  const [loading, setLoading] = useState(false)

  const handleCheckout = useCallback(async () => {
    if (customerAccessToken && customer?.email) {
      setLoading(true)
      // if (checkout?.email !== customer?.email) {
      //   await updateCustomer(customerAccessToken)
      //   setLoading(false)
      // }
      // if (replace) {
      //   window.location.replace(url)
      // } else {
      //   window.location.href = url
      // }
      await checkoutMultipass(customer?.email, checkout.id, checkoutUrl)
      // window.location = checkoutUrl
    } else {
      // navigate(routes.CART_LOGIN)

      // if (setActiveCart) {
      //   setActiveCart(false)
      // }
    }
  }, [customerAccessToken, customer])

  Component.displayName = name
  return <Component {...props} handleCheckout={handleCheckout} loading={loading} locales={locales} />
}
