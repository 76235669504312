import React, { createContext, useMemo, useState, useRef, useEffect } from "react"

import { useGlobal } from "../hooks/useGlobal"

export const ShopContext = createContext({})

export const ShopProvider = ({ children }) => {
  const { handleGlobalGroupReady } = useGlobal()
  const [shop, setShop] = useState(false)

  const prevShop = useRef(shop)

  useEffect(() => {
    if (!prevShop.current) {
      prevShop.current = shop
    }

    if (prevShop.current === false && shop) {
      handleGlobalGroupReady("shop")
    }
  }, [shop])

  const values = useMemo(() => ({
    shop,
    setShop,
  }), [shop])

  return <ShopContext.Provider value={{ ...values }}>{children}</ShopContext.Provider>
}
