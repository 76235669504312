import React, { useEffect } from "react"
import { navigate } from "gatsby"

import { useAnalytics } from "../../hooks/useAnalytics"
import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"
import { useLocation } from "../../hooks/useLocation"
import { useSettings } from "../../hooks/useSettings"

import { useCustomerAccessToken, useCustomerContext } from "../../hooks/useCustomer"

import { useCheckoutContext } from "../../hooks/useCheckout"
import { useMaintenance } from "../../hooks/useMaintenance"
import { useShopifyProduct } from "../../hooks/useShopify"
import { useFunctions } from "../../hooks/useFunctions"
import { useRoutes } from "../../hooks/useRoutes"

export const withLayout = Component => ({ name = `Layout`, children, location, data, ...props }) => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()
  const {
    config: {
      settings: { keys, params, routes },
    },
  } = useApp()

  const { storeConfig } = useLocation()

  const { trackEvent } = useAnalytics()
  const {
    maintenance: { enabled },
  } = useSettings()
  const { checkout } = useCheckoutContext()
  const { active, authorised } = useMaintenance(location)

  const { selectProduct } = useShopifyProduct()

  const onedaypay = location?.pathname.match(/^\/onedaypay\/:orderId\/charge/) || location?.pathname.match(/^\/onedaypay\/:orderId\/checkout/)

  useEffect(() => {
    selectProduct(data?.product, location?.pathname)
    trackEvent()
  }, [location?.pathname, checkout.currencyCode, data?.product, selectProduct, trackEvent])

  const { customer } = useCustomerContext()
  const { getCustomer } = useCustomerAccessToken()
  const { checkoutMultipass } = useFunctions()
  const { getUrlParameter } = useRoutes()

  const customerTokens = storage.get(keys?.customer)
  const invoiceUrl = getUrlParameter(params?.checkout)

  useEffect(() => {
    if (customerTokens?.accessToken && customer?.email && checkout?.id && invoiceUrl) {
      checkoutMultipass(customer?.email, checkout.id, invoiceUrl)
    }
  }, [customerTokens?.accessToken, customer?.email, checkout?.id, invoiceUrl])

  if (isBrowser && storeConfig?.wholesale) {
    if (!active && authorised && !invoiceUrl) {
      if (!customerTokens?.accessToken || new Date(customerTokens?.expiresAt) <= new Date()) {
        if (
          location.pathname !== routes?.ACCOUNT_LOGIN &&
          location.pathname !== routes?.ACCOUNT_FORGOT_PASSWORD &&
          !location.pathname.startsWith(routes?.ACCOUNT_ACTIVATE) &&
          !location.pathname.startsWith(routes?.ACCOUNT_RESET_PASSWORD)
        ) {
          storage.remove(keys?.customer)

          const route = `${routes.ACCOUNT_LOGIN}${location.pathname !== routes.HOMEPAGE ? `?${params?.continue}=${location.pathname}` : ``}`

          navigate(route, { replace: true })

          return null
        }
      }
    }
  }

  if (!customer?.id) getCustomer()

  Component.displayName = name

  return (
    <Component {...props} active={active} authorised={authorised} data={data} location={location} onedaypay={onedaypay} routes={routes}>
      {children}
    </Component>
  )
}
