import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCart } from "../../../hooks/useCart"
import { useCore } from "../../../hooks/useCore"
import { useLanguage } from "../../../hooks/useLanguage"
import { usePaymentPrices } from "../../../hooks/usePayments"
import { useRoutes } from "../../../hooks/useRoutes"
import { useWishlist } from "../../../hooks/useWishlist"

export const withCartItem = Component => ({ name = `CartItem`, drawer, grouped, lineItem, setActiveCart, setShowPopup, customPaymentEnabled }) => {
  const {
    config: {
      settings: { lists, params },
    },
  } = useApp()
  const {
    clearCart,
    removeFromCart,
    removeMultipleFromCart,
    updateQuantity,
    isMoqEnabled,
    getMinQuantityTag,
    getMinOrderQuantityFromTag,
    isVariantInCart
  } = useCart()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { usePaymentTerms } = usePaymentPrices()
  const { linkResolver } = useRoutes()
  const { moveToWishlist } = useWishlist()
  const [removing, setRemoving] = useState(false)
  const [minOrderQuantity, setMinOrderQuantity] = useState(0)
  const locales = {
    ...useLanguage(`payment`),
    ...useLanguage(`form`),
    ...useLanguage(`products`),
    ...useLanguage(`wishlist`),
    ...useLanguage(`cart`),
  }

  if (!lineItem.variant) {
    clearCart()
    return null
  }

  const { variant } = lineItem
  const product = {
    ...variant.product,
    handle: `${
      variant?.product?.tags?.find(tag => tag.includes(`parent:`))
        ? variant.product.tags
            .find(tag => tag.includes(`parent:`))
            .replace(`parent:`, ``)
            .trim()
        : variant.product.handle
    }`,
  }

  const productURL = `${linkResolver(product)?.url}?${params.variant}=${decodeShopifyId(variant?.id, `ProductVariant`)}`

  const paymentTerms = usePaymentTerms(product, variant)

  const handleQuantityChange = async qty => {
    setRemoving(true)
    await updateQuantity(variant.id, qty)
    setRemoving(false)
  }

  const handleRemove = async ({ redirect, wishlist }) => {
    setRemoving(true)
    const input: Array<{ variantId: string; qty?: number; groupedId?: string }> = [{ variantId: variant.id }]

    if (grouped?.length > 0) {
      for (const item of grouped) {
        input.push({ variantId: item.variant?.id, groupedId: item.customAttributes?.find(attribute => attribute.key === "_grouped")?.value })
      }
    }

    await removeMultipleFromCart(input)

    setRemoving(false)
    handleRedirectWishlist({ redirect, wishlist })
  }

  const handleRedirectWishlist = async ({ redirect, wishlist }) => {
    if (redirect) {
      navigate(productURL)
    }

    if (wishlist) {
      moveToWishlist({ ...variant, ...product, list: lists[0].key }, variant?.id)
    }
  }

  useEffect(() => {
    if (!isMoqEnabled(product)) return
    
    const minQuantityTag = getMinQuantityTag(product.tags)
    
    const minQuantity = getMinOrderQuantityFromTag(minQuantityTag)
    
    if (!minQuantityTag || !minQuantity) return

    setMinOrderQuantity(minQuantity)
    
  }, [minOrderQuantity])

  Component.displayName = name
  return (
    <Component
      customPaymentEnabled={customPaymentEnabled}
      drawer={drawer}
      grouped={grouped}
      handleQuantityChange={handleQuantityChange}
      handleRemove={handleRemove}
      lineItem={lineItem}
      locales={locales}
      minOrderQuantity={minOrderQuantity}
      paymentTerms={paymentTerms}
      product={product}
      productURL={productURL}
      removing={removing}
      setActiveCart={setActiveCart}
      setShowPopup={setShowPopup}
      variant={variant}
    />
  )
}
