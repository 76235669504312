import React, { createContext, useEffect, useState } from "react"
import firebase from "firebase/app"
import "firebase/functions"
import "firebase/auth"
import "firebase/firestore"

import { useCore } from "../hooks/useCore"
import { useGlobal } from "../hooks/useGlobal"

export const FirebaseContext = createContext({ firebase: null, app: null })

export const FirebaseProvider = ({ children, config }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const { handleGlobalGroupReady } = useGlobal()
  const [app, setFirebase] = useState(null)

  useEffect(() => {
    handleGlobalGroupReady("firebase")
  }, [])

  if (isBrowser) {
    const firebaseApp = firebase.apps.length
      ? firebase.apps[0]
      : firebase.initializeApp({
          apiKey: config.apiKey,
          authDomain: config.authDomain,
          databaseURL: config.databaseURL,
          projectId: config.projectId,
        })

    if (process.env.NODE_ENV === "development")
      firebaseApp.firestore().settings({
        host: "localhost:8082",
        ssl: false,
      })

    if (!app) setFirebase(firebaseApp)
    return <FirebaseContext.Provider value={{ firebase, app }}>{children}</FirebaseContext.Provider>
  } else {
    return <>{children}</>
  }
}

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>{({ firebase }) => <Component {...props} firebase={firebase} />}</FirebaseContext.Consumer>
)
