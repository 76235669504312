import React from "react"
import { Link } from "gatsby"

import { withCartFormTotals } from "./withCartFormTotals"
import { CartFormCheckout } from "./CartFormCheckout"
import { CartItemPrice } from "../Item/CartItemPrice"
import { PrimaryButton } from "../../Styled/Button"
import { Col, Note, Row, PaymentLabel, PaymentLabelLink } from "../CartStyles"

interface Props {
  checkout: any
  customerPayments: any
  drawer: boolean
  discountsTotal: any
  giftCardTotal: any
  locales: any
  setActiveCart: Function
  setShowPopup: Function
  url: string
}

export const CartFormTotals = withCartFormTotals(
  ({ checkout, customerPayments, drawer, discountsTotal, giftCardTotal, locales, setActiveCart, setShowPopup, url }: Props) => (
    <>
      <Row spacing>
        <Col>{locales.subtotal}</Col>
        <Col right>
          <CartItemPrice money={checkout?.lineItemsSubtotalPrice} />
        </Col>
      </Row>

      {checkout?.discountApplications?.length > 0 && (
        <Row spacing>
          <Col>{locales.discountsTitle}</Col>
          <Col right>
            <CartItemPrice
              money={{
                ...checkout.totalPriceV2,
                amount: -discountsTotal,
              }}
            />
          </Col>
        </Row>
      )}

      {checkout?.appliedGiftCards?.length > 0 && (
        <Row spacing>
          <Col>{locales.giftcardsTitle}</Col>
          <Col right>
            <CartItemPrice
              money={{
                ...checkout.totalPriceV2,
                amount: -giftCardTotal,
              }}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>{locales.total}</Col>
        <Col right>
          <CartItemPrice money={checkout?.paymentDueV2} withCurrency />
        </Col>
      </Row>

      <Note>{locales.shipping}</Note>

      {customerPayments && (
        <PaymentLabel condensed>
          {`${locales.cartLine1} `}
          <PaymentLabelLink onClick={() => setShowPopup(true)}>{locales.cartLink2}</PaymentLabelLink>
        </PaymentLabel>
      )}

      {drawer && setActiveCart && (
        <Row condensed>
          <Col condensed>
            <PrimaryButton as={Link} full={`true`} onClick={() => setActiveCart(false)} to={url}>
              {locales.viewButton}
            </PrimaryButton>
          </Col>

          <Col condensed>
            <CartFormCheckout setActiveCart={setActiveCart} />
          </Col>
        </Row>
      )}
    </>
  )
)
