import { useMemo, useState, useEffect, useCallback, useContext } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { navigate } from "gatsby"

import { useApp } from "./useApp"
import { useCheckout } from "./useCheckout"
import { useCore } from "./useCore"
import { useLocation } from "./useLocation"
import { useQueries } from "./useQueries"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"
import { useFunctions } from "./useFunctions"
import { useFirebase } from "./useFirebase"

import { CustomerContext } from "../providers/customer"
import { useQuery } from "@apollo/client"

export const useCustomerContext = () => {
  const customerData = useContext(CustomerContext)
  return { ...customerData }
}

export const useCustomerAccessToken = () => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    mutations: { CUSTOMER_ACCESS_TOKEN_CREATE, CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS },
    queries: { GET_CUSTOMER },
  } = useQueries()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const { countryCode } = useLocation()

  const { setCustomer } = useCustomerContext()

  const { useMutation, useQuery } = useShopify()
  const { updateCustomer } = useCheckout()

  const [customerAccessTokenCreate] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE)
  const [customerAccessTokenCreateWithMultipass] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS)
  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, { fetchPolicy: "no-cache", skip: true })

  const getCustomer = useCallback(async () => {
    const customerTokens = storage.get(keys?.customer)

    if (customerTokens?.accessToken) {
      try {
        const {
          data: { customer, customerUserErrors },
        } = await getCustomerQuery({
          customerAccessToken: customerTokens?.accessToken,
          countryCode: countryCode,
        })

        if (!customerUserErrors?.length) {
          setCustomer(customer)
        }
        if (customerUserErrors?.length) storage.remove(keys?.customer)
      } catch (err) {
        console.error(err)
      }
    }
  }, [getCustomerQuery, setCustomer, keys, storage])

  const createAccessToken = useCallback(
    async (email, password) => {
      try {
        const {
          data: {
            customerAccessTokenCreate: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreate({
          variables: { input: { email, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          storage.set(keys?.customer, { accessToken, expiresAt })
          updateCustomer(accessToken)
          getCustomer()
        }

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [customerAccessTokenCreate, updateCustomer, getCustomer, keys, storage]
  )

  const createAccessTokenWithMultipass = useCallback(
    async multipassToken => {
      try {
        const {
          data: {
            customerAccessTokenCreateWithMultipass: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreateWithMultipass({
          variables: { multipassToken },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          storage.set(keys?.customer, { accessToken, expiresAt })
          updateCustomer(accessToken)
          getCustomer()
        }

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [customerAccessTokenCreateWithMultipass, updateCustomer, getCustomer, keys, storage]
  )

  return { createAccessToken, createAccessTokenWithMultipass, getCustomer }
}

export const useCustomerRegister = () => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const {
    mutations: { CUSTOMER_CREATE },
  } = useQueries()
  const { useMutation } = useShopify()
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { createAccessToken } = useCustomerAccessToken()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "", password: "", firstName: "", lastName: "", phone: "", acceptsMarketing: true })

  const [customerCreate] = useMutation(CUSTOMER_CREATE)

  const createCustomer = useCallback(
    async ({ ...userData }, noRedirect = false) => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerCreate: { customerUserErrors: errors },
          },
        } = await customerCreate({
          variables: { input: { ...userData } },
        })

        if (errors?.length) {
          setErrors(errors)
          setLoading(false)
        } else {
          const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)

          if (!customerUserErrors?.length) {
            setLoading(false)
            if (isBrowser && !noRedirect) navigate(routes?.ACCOUNT, { replace: true })
          } else {
            setErrors(customerUserErrors)
            setLoading(false)
          }
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerCreate, createAccessToken, routes, isBrowser]
  )

  return { createCustomer, data, setData, loading, errors }
}

export const useCustomerLogin = () => {
  const {
    config: {
      settings: { params, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { createAccessToken } = useCustomerAccessToken()
  const { getUrlParameter } = useRoutes()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "", password: "" })

  const loginCustomer = useCallback(
    async ({ ...userData }, noRedirect = false) => {
      setLoading(true)
      setErrors([])

      try {
        const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)

        if (!customerUserErrors?.length) {
          setLoading(false)
          if (isBrowser && !noRedirect) navigate(getUrlParameter(params?.continue) || routes.ACCOUNT, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, createAccessToken, routes, isBrowser, getUrlParameter, params]
  )

  return { loginCustomer, data, setData, loading, errors }
}

export const useCustomerLogout = () => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()

  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()

  const { app } = useFirebase()

  const { setCustomer } = useCustomerContext()

  const logoutCustomer = useCallback(() => {
    app.auth().signOut()
    storage.remove(keys?.customer)
    if (isBrowser) navigate(routes?.ACCOUNT, { replace: true })
    setCustomer(null)
  }, [setCustomer, keys, storage, routes, isBrowser, app])

  return { logoutCustomer }
}

export const useCustomerRecover = () => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()
  const {
    mutations: { CUSTOMER_RECOVER },
  } = useQueries()

  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()

  const { useMutation } = useShopify()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [success, setSuccess] = useState(false)
  const [data, setData] = useState({ email: "" })

  const [customerRecover] = useMutation(CUSTOMER_RECOVER)

  const recoverCustomer = useCallback(
    async (email, noRedirect = false) => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerRecover: { customerUserErrors },
          },
        } = await customerRecover({
          variables: { email },
        })

        if (!customerUserErrors?.length) {
          storage.remove(keys?.customer)
          setSuccess(true)
          if (isBrowser && !noRedirect) navigate(routes?.LOGIN, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, setSuccess, customerRecover, storage, keys, routes, isBrowser]
  )

  return { recoverCustomer, data, setData, loading, errors, success }
}

export const useCustomerAccount = () => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()
  const {
    mutations: { CUSTOMER_RESET, CUSTOMER_ACTIVATE },
  } = useQueries()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const { useMutation } = useShopify()
  const { updateCustomer } = useCheckout()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ password: "" })

  const [customerReset] = useMutation(CUSTOMER_RESET)
  const [customerActivate] = useMutation(CUSTOMER_ACTIVATE)

  const resetCustomer = useCallback(
    async (customerId, resetToken, password, noRedirect = false) => {
      setLoading(true)
      setErrors([])

      try {
        const id = Buffer.from(`gid://shopify/Customer/${customerId}`, "utf8").toString("base64")

        const {
          data: {
            customerReset: { customerAccessToken, customerUserErrors },
          },
        } = await customerReset({
          variables: { id, input: { resetToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          setSuccess(true)
          if (isBrowser && !noRedirect) navigate(routes?.ACCOUNT, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, setSuccess, customerReset, updateCustomer, storage, keys, routes, isBrowser]
  )

  const activateCustomer = useCallback(
    async (customerId, activationToken, password, noRedirect = false) => {
      setLoading(true)
      setErrors([])

      try {
        const id = Buffer.from(`gid://shopify/Customer/${customerId}`, "utf8").toString("base64")

        const {
          data: {
            customerActivate: { customerAccessToken, customerUserErrors },
          },
        } = await customerActivate({
          variables: { id, input: { activationToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          setSuccess(true)
          if (isBrowser && !noRedirect) navigate(routes?.ACCOUNT, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, setSuccess, customerActivate, updateCustomer, storage, keys, routes, isBrowser]
  )

  return { resetCustomer, activateCustomer, data, setData, loading, errors, success }
}

export const useCustomerOrders = (perPage: number) => {
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const { countryCode } = useLocation()
  const {
    queries: { GET_CUSTOMER_ORDERS },
  } = useQueries()
  const { edgeNormaliser, useLazyQuery } = useShopify()
  const [refetch, { data, loading, error }] = useLazyQuery(GET_CUSTOMER_ORDERS)
  const [page, setPage] = useState<number>(1)

  const customerTokens = storage.get(keys?.customer)
  const customerAccessToken = customerTokens?.accessToken
  const variables = { countryCode, customerAccessToken }

  useEffect(() => {
    refetch({ variables: { ...variables, first: perPage } })
  }, [])

  const hasPreviousPage = data?.customer?.orders?.pageInfo?.hasPreviousPage
  const hasNextPage = data?.customer?.orders?.pageInfo?.hasNextPage
  const orderCount = Number(data?.customer?.numberOfOrders || 0)

  const orders = edgeNormaliser(data?.customer?.orders) || []
  const totalPages = Math.ceil(orderCount / perPage)

  const handlePreviousPage = useCallback(() => {
    if (hasPreviousPage) {
      const cursor = data?.customer?.orders?.pageInfo?.startCursor
      refetch({ variables: { ...variables, last: perPage, before: cursor } })
      setPage(prevState => prevState - 1)
    }
  }, [hasPreviousPage, setPage, data, refetch])

  const handleNextPage = useCallback(() => {
    if (hasNextPage) {
      const cursor = data?.customer?.orders?.pageInfo?.endCursor
      refetch({ variables: { ...variables, first: perPage, after: cursor } })
      setPage(prevState => prevState + 1)
    }
  }, [hasNextPage, setPage, data, refetch])

  return { error, handleNextPage, handlePreviousPage, hasNextPage, hasPreviousPage, loading, orders, page, totalPages }
}

export const useCustomerOrder = (orderId, key) => {
  const {
    helpers: { encodeBase64 },
  } = useCore()
  const { countryCode } = useLocation()
  const {
    queries: { GET_ORDER },
  } = useQueries()
  const { useQuery } = useShopify()
  const id = encodeBase64(`gid://shopify/Order/${orderId}${key}`)

  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: {
      id,
      countryCode,
    },
  })

  const order = data?.node || {}

  return { order, loading, error }
}

export const useCustomerAddresses = () => {
  const {
    helpers: { storage },
  } = useCore()

  const { countryCode } = useLocation()
  const {
    mutations: { CUSTOMER_ADDRESS_CREATE, CUSTOMER_ADDRESS_UPDATE, CUSTOMER_ADDRESS_DELETE, CUSTOMER_DEFAULT_ADDRESS_UPDATE },
    queries: { GET_CUSTOMER },
  } = useQueries()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useMutation, useLazyQuery } = useShopify()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = {
    address1: "",
    address2: "",
    city: "",
    company: "",
    country: "",
    firstName: "",
    lastName: "",
    phone: "",
    province: "",
    zip: "",
  }
  const [address, setAddress] = useState({ ...initialData, id: "", action: "" })
  const [savedAddress, setSavedAddress] = useState(null)
  const [addresses, setAddresses] = useState([])
  const customerTokens = storage.get(keys?.customer)
  const customerAccessToken = customerTokens?.accessToken

  const [customerAddressCreate] = useMutation(CUSTOMER_ADDRESS_CREATE)
  const [customerAddressUpdate] = useMutation(CUSTOMER_ADDRESS_UPDATE)
  const [customerAddressDelete] = useMutation(CUSTOMER_ADDRESS_DELETE)
  const [customerDefaultAddressUpdate] = useMutation(CUSTOMER_DEFAULT_ADDRESS_UPDATE)

  const filterData = address =>
    Object.keys(address)
      .filter(key => Object.keys(initialData).includes(key))
      .reduce((obj, key) => {
        obj[key] = address[key]
        return obj
      }, {})

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      customerAccessToken,
      countryCode,
    },
  })

  useEffect(() => {
    getAll()
  }, [saving, getAll])

  useEffect(() => {
    if (data?.customer) {
      setAddresses(
        data?.customer?.addresses?.edges?.map(({ node }) => ({
          ...node,
          default: node?.id === data?.customer?.defaultAddress?.id,
        }))
      )
    }
  }, [data])

  const createAddress = useCallback(
    async address => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressCreate: { customerAddress, customerUserErrors },
          },
        } = await customerAddressCreate({
          variables: { customerAccessToken, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          if (address?.default) {
            defaultAddress(customerAddress?.id)
          }
          setAddress({ ...initialData, id: "", action: "" })
          setSavedAddress(null)
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setAddress, setSavedAddress, customerAddressCreate, filterData, initialData, customerAccessToken]
  )

  const updateAddress = useCallback(
    async (id, address) => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressUpdate: { customerUserErrors },
          },
        } = await customerAddressUpdate({
          variables: { customerAccessToken, id, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          if (address?.default) {
            await defaultAddress(address?.id)
          }
          setAddress({ ...initialData, id: "", action: "" })
          setSavedAddress(null)
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setAddress, setSavedAddress, customerAddressUpdate, filterData, initialData, customerAccessToken]
  )

  const defaultAddress = useCallback(
    async addressId => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerDefaultAddressUpdate: { customerUserErrors },
          },
        } = await customerDefaultAddressUpdate({
          variables: { addressId, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerDefaultAddressUpdate, customerAccessToken]
  )

  const deleteAddress = useCallback(
    async id => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressDelete: { customerUserErrors },
          },
        } = await customerAddressDelete({
          variables: { id, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerAddressDelete, customerAccessToken]
  )

  return {
    addresses,
    setAddress,
    savedAddress,
    setSavedAddress,
    address,
    createAddress,
    updateAddress,
    defaultAddress,
    deleteAddress,
    initialData,
    loading,
    saving,
    errors,
  }
}

export const useCustomerDetails = () => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    mutations: { CUSTOMER_UPDATE },
    queries: { GET_CUSTOMER },
  } = useQueries()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { countryCode } = useLocation()
  const { useLazyQuery, useMutation } = useShopify()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    acceptsMarketing: false,
  }

  const [customerUpdate] = useMutation(CUSTOMER_UPDATE)
  const [customer, setCustomer] = useState(initialData)
  const [savedCustomer, setSavedCustomer] = useState(null)
  const customerTokens = storage.get(keys?.customer)
  const customerAccessToken = customerTokens?.accessToken

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      customerAccessToken,
      countryCode,
    },
  })

  const filterData = data =>
    Object.keys(data)
      .filter(key => Object.keys(initialData).includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key]
        return obj
      }, {})

  useEffect(() => {
    if (customerAccessToken?.length) getAll()
  }, [])

  useEffect(() => {
    if (data?.customer) {
      setCustomer(data?.customer)
      if (!savedCustomer) setSavedCustomer(data?.customer)
    }
  }, [data])

  const updateCustomer = useCallback(
    async customer => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerUpdate: { customerUserErrors },
          },
        } = await customerUpdate({
          variables: { customerAccessToken, customer: filterData(customer) },
        })

        if (!customerUserErrors?.length) {
          setCustomer(prevState => ({
            ...prevState,
            action: false,
          }))
          setSavedCustomer(filterData(customer))
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setCustomer, customerUpdate, filterData, customerAccessToken]
  )

  return { customer, setCustomer, savedCustomer, updateCustomer, loading, saving, errors, customerAccessToken }
}

export const useCustomerSocialLogin = () => {
  const {
    config: {
      settings: { params, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { firebase, app } = useFirebase()
  const { customerMultipass } = useFunctions()
  const { getUrlParameter } = useRoutes()
  const { createAccessTokenWithMultipass } = useCustomerAccessToken()
  const [isLoading, setLoading] = useState(false)
  const [isErrors, setErrors] = useState([])
  const [user, loading, errors] = useAuthState(firebase?.auth() || { currentUser: null })
  const isCheckout = getUrlParameter(params.checkoutRef) === "checkout"

  const providers = firebase
    ? {
        google: new firebase.auth.GoogleAuthProvider(),
        facebook: new firebase.auth.FacebookAuthProvider(),
      }
    : {}

  const loginCustomer = useCallback(
    async providerName => {
      setLoading(true)
      setErrors([])
      app.auth().signInWithRedirect(providers[providerName])
    },
    [setLoading, setErrors, app, providers]
  )

  const processRedirect = useCallback(async () => {
    setLoading(true)
    setErrors([])

    try {
      const firebaseToken = await user.getIdToken()
      const { multipassToken } = await customerMultipass(firebaseToken)

      if (multipassToken) {
        const { customerUserErrors } = await createAccessTokenWithMultipass(multipassToken)

        if (!customerUserErrors?.length) {
          if (isBrowser) navigate(routes?.ACCOUNT, { replace: true, state: { checkout: isCheckout } })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } else {
        setErrors(["Error generating multipass token"])
        setLoading(false)
      }
    } catch (err) {
      console.error(err)
      setErrors([err])
      setLoading(false)
    }
  }, [createAccessTokenWithMultipass, customerMultipass, user, isBrowser, isCheckout, routes])

  useEffect(() => {
    if (user?.email) processRedirect()
  }, [user, loading, processRedirect])

  return { loginCustomer, loading: loading || isLoading, errors: errors || isErrors }
}
