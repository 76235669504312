import { useState, useCallback, useEffect } from "react"

import { useAnalytics } from "./useAnalytics"
import { useApp } from "./useApp"
import { useCheckoutContext } from "./useCheckout"
import { useLocation } from "./useLocation"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"
import { useQueries } from "./useQueries"

export const useCart = () => {
  const {
    config: { settings },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const {
    mutations: { CHECKOUT_LINE_ITEMS_REPLACE },
  } = useQueries()
  const { countryCode } = useLocation()

  const { keys, products } = settings
  const { checkout, setCheckout } = useCheckoutContext()
  const { useMutation, checkoutNormaliser } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [moqEnabled, setMoqEnabled] = useState(false)
  const [variantInCart, setVariantInCart] = useState(false)

  const [checkoutId, setCheckoutId] = useState(checkout?.id || storage.get(keys?.checkout))
  useEffect(() => setCheckoutId(checkout?.id || storage.get(keys?.checkout)), [])

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)

  const addToCart = useCallback(
    async (variantId, quantity = 1, customAttributes = [], haveCustomisedTag) => {
      setLoading(true)
      let alreadyInCart = false


      const lineItems =
        checkout?.lineItems?.map(lineItem => {
          if ((lineItem?.variant?.id === variantId) && (haveCustomisedTag === false || lineItem.variant.product.productType === settings.products.wholesale.disableCustomisationsFor)) {
            alreadyInCart = true
            return {
              customAttributes: [
                ...lineItem?.customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })),
                ...(customAttributes || []),
              ],
              quantity: lineItem?.quantity + quantity,
              variantId,
            }
          }
          return {
            customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
              key,
              value,
            })),
            quantity: lineItem?.quantity,
            variantId: lineItem?.variant?.id,
          }
        }) || []

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems: [...(alreadyInCart ? lineItems : [...lineItems, { quantity, variantId, customAttributes }])],
          countryCode
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        await setCheckout(checkoutNormaliser(data?.checkout))
      }
      setLoading(false)

      trackCartUpdate("add", variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, trackCartUpdate, countryCode]
  )

  const addMultipleToCart = useCallback(
    async (input: Array<{ variantId: number; quantity: number; customAttributes: Array<{ [key: string]: string }> }>) => {
      setLoading(true)
      let lineItems = checkout?.lineItems || []

      for (const item of input) {
        let alreadyInCart = false
        const convertedLineItems =
          lineItems.map(lineItem => {
            const isGroupedItem = lineItem?.customAttributes?.find(attribute => attribute.key === "_grouped")

            if (lineItem?.variant?.id === item.variantId && !isGroupedItem) {
              alreadyInCart = true
              return {
                customAttributes: [
                  ...lineItem?.customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })),
                  ...(item.customAttributes || []),
                ],
                quantity: lineItem?.quantity + item.quantity || 1,
                variantId: item.variantId,
              }
            }
            return {
              customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
                key,
                value,
              })),
              quantity: lineItem?.quantity,
              variantId: lineItem?.variant?.id || lineItem?.variantId,
            }
          }) || []

        lineItems = alreadyInCart
          ? convertedLineItems
          : [...convertedLineItems, { quantity: item.quantity || 1, variantId: item.variantId, customAttributes: item.customAttributes || [] }]
      }

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        await setCheckout(checkoutNormaliser(data?.checkout))
      }
      setLoading(false)

      for (const item of input) {
        trackCartUpdate("add", item.variantId, item.quantity, checkoutNormaliser(data?.checkout)?.lineItems)
      }
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, countryCode, trackCartUpdate]
  )

  const removeFromCart = useCallback(
    async variantId => {
      setLoading(true)

      const quantity = checkout?.lineItems.filter(({ variant }) => variant.id === variantId).map(({ quantity }) => quantity)[0] || 1
      trackCartUpdate("remove", variantId, quantity, checkout?.lineItems)

      const lineItems = checkout?.lineItems
        .filter(lineItem => lineItem.variant.id !== variantId)
        .map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id,
        }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) await setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, countryCode, trackCartUpdate]
  )

  const removeMultipleFromCart = useCallback(
    async (input: Array<{ variantId: string; qty?: number; groupedId?: string }>) => {
      setLoading(true)

      // Normalize lineItems
      let lineItems: Array<{ variantId: string; quantity: number; customAttributes?: Array<{ key: string; value: string }> }> =
        checkout?.lineItems?.map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant?.id || lineItem.variantId,
        })) || []

      // Replace quantity
      for (const item of input) {
        const quantity =
          item.qty || checkout?.lineItems.filter(({ variant }) => variant.id === item.variantId).map(({ quantity }) => quantity)[0] || 1
        trackCartUpdate("remove", item.variantId, quantity, checkout?.lineItems)

        lineItems = lineItems.map(lineItem => {
          if (
            (lineItem.variantId === item.variantId || lineItem.variantId === item.variantId) &&
            (!item.groupedId ||
              (item.groupedId && lineItem.customAttributes?.find(attribute => attribute.key === "_grouped")?.value === item.groupedId))
          ) {
            return {
              ...(lineItem.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
              }),
              quantity: item.qty ? lineItem.quantity - item.qty : 0,
              variantId: lineItem.variantId || lineItem.variantId,
            }
          }

          return lineItem
        })
      }

      // Filter deleted items
      lineItems = lineItems.filter(lineItem => lineItem.quantity)

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) await setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, countryCode, trackCartUpdate]
  )

  const updateQuantity = useCallback(
    async (variantId, quantity, action = "add") => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.variant.id === variantId ? quantity : lineItem.quantity,
        variantId: lineItem.variant.id,
      }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) await setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)

      trackCartUpdate(action, variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [trackCartUpdate, lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, countryCode]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId) => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.quantity,
        variantId: lineItem.variant.id === prevVariantId ? variantId : lineItem.variant.id,
      }))
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, countryCode]
  )

  const updateItem = useCallback(
    async (variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.variant.id === variantId
          ? {
            customAttributes: [
              ...new Map(
                [
                  ...lineItem?.customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })),
                  ...Object.entries(customAttributes)?.map(attr => ({
                    key: attr[0],
                    value: attr[1],
                  })),
                ].map(item => [item?.key, item])
              ).values(),
            ],
            variantId,
            quantity,
          }
          : {
            ...(lineItem?.customAttributes && {
              customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                key,
                value,
              })),
            }),
            quantity: lineItem.quantity,
            variantId: lineItem.variant.id,
          }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, countryCode]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)
    checkout?.lineItems?.map(({ variant, quantity }) => trackCartUpdate("remove", variant?.id, quantity, checkout?.lineItems))

    const {
      data: { checkoutLineItemsReplace: data, userErrors: errors },
    } = await lineItemsReplace({
      variables: {
        checkoutId,
        lineItems: [],
        countryCode
      },
    })

    if (errors?.length) setErrors(errors)
    if (data) setCheckout(checkoutNormaliser(data?.checkout))

    setLoading(false)
  }, [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, countryCode, trackCartUpdate])

  const getGroupedItems = (lineItem, lineItems) =>
    lineItems.filter(
      item => item.customAttributes && item.customAttributes.find(({ key, value }) => key === `_grouped` && value === lineItem.variant.id)
    )
  const excludeGroupedAndWrappingItems = lineItems => {
    if (lineItems?.edges) {
      return []
    }

    return lineItems
      ?.filter(lineItem => lineItem?.variant?.addToCart?.handle !== products.giftWrappingHandle)
      ?.filter(lineItem => !(lineItem.customAttributes && lineItem.customAttributes.find(({ key }) => key === `_grouped`)))
  }

  const isVariantInCart = useCallback((variantSelected): boolean => {
    checkout?.lineItems?.forEach(lineItem => {
      if (variantSelected === lineItem.variant.id) {
        return true
      }
    })
    return false
  }, [checkout, moqEnabled, variantInCart])

  const getVariantQuantityInCart = useCallback((variantSelected): number => {
    let qty = 0
    checkout?.lineItems?.forEach(lineItem => {
      if (variantSelected === lineItem.variant.id) {
        qty = qty + lineItem.quantity
      }
    })

    return qty;
  }, [checkout, moqEnabled, variantInCart])

  const getMinOrderQuantityFromTag = useCallback((tag): number => {
    if (!tag) return 1

    const qty = Number(tag.split(":").pop());

    if (!qty) return 1

    return qty;
  }, [])

  const isMoqEnabled = useCallback((product) => {
    if (!product) return false
    if (
      product?.tags?.filter(tag => tag.includes(settings.products.wholesale.tags.moqEnabled)).length > 0
      && product?.tags.join().includes(settings.products.wholesale.tags.moqQuantity)
    ) {
      return true;
    }
  }, [])

  const getMinQuantityTag = useCallback((productTags) => {
    const minQuantityTag = productTags?.filter(tag => tag.includes(settings.products.wholesale.tags.moqQuantity))

    return minQuantityTag[0]
  }, [])

  return {
    getGroupedItems,
    getMinOrderQuantityFromTag,
    getMinQuantityTag,
    getVariantQuantityInCart,
    isVariantInCart,
    isMoqEnabled,
    excludeGroupedAndWrappingItems,
    addToCart,
    addMultipleToCart,
    removeFromCart,
    removeMultipleFromCart,
    updateQuantity,
    updateVariant,
    updateItem,
    clearCart,
    loading,
    errors,
  }
}
