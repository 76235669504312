import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { Link } from "gatsby"

import { useRoutes } from "../../../hooks/useRoutes"
import { Image } from "../../Image/Image"

export const withRichTextContent = Component => ({ name = `RichTextContent`, content: rawContent, rte }) => {
  const { linkResolver } = useRoutes()

  const characterNormaliser = content =>
    content && content.length && Array.isArray(content)
      ? content.map(content => ({
          ...content,
          children:
            content.children && content.children.length
              ? content.children.map(child => ({
                  ...child,
                  text: child.text ? child.text.replace(/[^\x20-\x7E]+/g, ``) : "",
                }))
              : null,
        }))
      : null

  const content = characterNormaliser(rawContent)

  const serializers = {
    marks: {
      internalDocument: ({ children, mark }) => (
        <Link to={mark?.internalDocument?.link ? linkResolver(mark.internalDocument.link)?.url : null}>{children}</Link>
      ),
    },
    types: {
      code: ({ node }) => (
        <pre data-language={node.language}>
          <code>{node.code}</code>
        </pre>
      ),
      imageType: ({ node }) => <Image alt={node.alt} inline src={node?.asset?.url} />,
      block: props => {
        for (const ele of props.children) {
          if (typeof ele === "string" && ele.includes("|")) {
            return React.createElement("pre", { style: { fontFamily: "inherit" } }, props.children)
          }
        }

        return BlockContent.defaultSerializers.types.block(props)
      },
    },
  }

  Component.displayName = name
  return <Component content={content} rte={rte} serializers={serializers} />
}
