import React, { useState, useEffect, useRef } from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { useLocation } from "../../../hooks/useLocation"
import { useRoutes } from "../../../hooks/useRoutes"

export const withSearchItem = Component => ({
  name = `SearchItem`,
  index = 0,
  hidden,
  product,
  ratio,
  screenWidth,
  horizontalSpacing,
  verticalSpacing,
  width,
  hoverImageIndex,
  onClick,
  collection,
  list = "Featured Products",
}) => {
  const [displayHover, setDisplayHover] = useState(false)
  const { trackProductImpression, trackProductClick, VisibilitySensor } = useAnalytics()
  const { storeConfig } = useLocation()
  const { linkResolver } = useRoutes()
  const itemRef = useRef(null)

  const hoverIndex = hoverImageIndex === "Last" ? product.images.length - 1 : parseInt(hoverImageIndex) ? parseInt(hoverImageIndex) - 1 : 1
  const primaryImage = product?.images?.[0]
  const secondaryImage = product?.images?.length > 1 && product?.images[hoverIndex]
  const defaultVariant = product?.variants?.[0]
  const handleHover = state => product?.images?.length > 1 && setDisplayHover(state)
  const url = linkResolver(product, `product`)?.url

  const handleClick = () => {
    trackProductClick(product, product?.variants?.[0], index, list)

    if (onClick) {
      onClick()
    }
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (itemRef && itemRef.current && !itemRef.current.contains(event.target)) {
        setDisplayHover(false)
      } else {
        setDisplayHover(true)
      }
    }

    document.addEventListener("touchmove", handleClickOutside)
    return () => {
      document.removeEventListener("touchmove", handleClickOutside)
    }
  }, [itemRef])

  Component.displayName = name
  return (
    <VisibilitySensor onChange={visible => visible && trackProductImpression(product, index, list)}>
      <Component
        collection={collection}
        defaultVariant={defaultVariant}
        displayHover={displayHover}
        hidden={hidden}
        handleHover={handleHover}
        horizontalSpacing={horizontalSpacing}
        itemRef={itemRef}
        onClick={handleClick}
        primaryImage={primaryImage}
        product={product}
        ratio={ratio}
        screenWidth={screenWidth}
        secondaryImage={secondaryImage}
        url={url}
        verticalSpacing={verticalSpacing}
        wholesale={storeConfig?.wholesale}
        width={width}
      />
    </VisibilitySensor>
  )
}
