import React from "react"

import { SearchComponents } from "../../../hooks/useSearch"

export const SearchSensorPublished = () => (
  <SearchComponents.ReactiveComponent
    componentId="PublishedSensor"
    customQuery={() => ({
      query: {
        match: {
          published: true,
        },
      },
    })}
  />
)
