import React, { useEffect, useState } from "react"
import * as Search from "@appbaseio/reactivesearch"

import { useApp } from "../hooks/useApp"
import { useLocation } from "../hooks/useLocation"

export const SearchProvider = ({ children }) => {
  const [renderKey, setRenderKey] = useState(0)
  const {
    config: {
      services: { reactify },
    },
  } = useApp()
  const { storeConfig } = useLocation()

  const updateRender = () => setRenderKey(prev => prev + 1)

  useEffect(() => {
    window.addEventListener(`refreshSearch`, updateRender)
    return () => window.removeEventListener(`refreshSearch`, updateRender)
  }, [])

  return (
    <Search.ReactiveBase
      key={renderKey}
      app={storeConfig?.searchIndex}
      url={reactify?.searchUrl}
      theme={{
        typography: {
          fontFamily: "inherit",
        },
      }}
    >
      {children}
    </Search.ReactiveBase>
  )
}

export const SearchComponents = {
  ...Search,
}
