import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useWishlist } from "../../../hooks/useWishlist"

export const withWishlistButton = Component => ({ name = `WishlistButton`, item, wishlistName }) => {
  const {
    config: {
      settings: { lists },
    },
  } = useApp()
  const list = wishlistName || lists?.[0]?.key
  const { addToWishlist, deleteFromWishlist, existsInWishlist, loading } = useWishlist()

  const inWishlist = existsInWishlist(item?.id)

  const handleToggle = () => {
    inWishlist
      ? deleteFromWishlist(item?.id)
      : addToWishlist({
          ...item,
          list,
        })
  }

  Component.displayName = name
  return item?.id ? <Component handleToggle={handleToggle} inWishlist={inWishlist} loading={loading} /> : null
}
