import React, { createContext, useState, useRef, useEffect, useMemo } from "react"
import * as Sentry from "@sentry/gatsby"

import { useGlobal } from "../hooks/useGlobal"

export const CustomerContext = createContext({})

export const CustomerProvider = ({ children }) => {
  const { handleGlobalGroupReady } = useGlobal()
  const [customer, setCustomer] = useState(false)

  const prevCustomer = useRef(customer)

  useEffect(() => {
    if (!prevCustomer.current) {
      prevCustomer.current = customer
    }

    if (customer) {
      Sentry.setUser({ email: customer.email })
    }

    if (prevCustomer.current === false && customer) {
      handleGlobalGroupReady("customer")
    }
  }, [customer])

  const providerValues = useMemo(() => ({
    customer,
    setCustomer,
  }), [customer, setCustomer])

  return <CustomerContext.Provider value={providerValues}>{children}</CustomerContext.Provider>
}
