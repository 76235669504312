import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import FacebookSvg from "static/icons/social/facebook.svg"
import TwitterSvg from "static/icons/social/twitter.svg"
import PinterestSvg from "static/icons/social/pinterest.svg"
import ShareSvg from "static/icons/theme/link.svg"
import SavedSvg from "static/icons/theme/wishlist-inactive.svg"
import ActiveSvg from "static/icons/theme/wishlist-active.svg"
import InactiveSvg from "static/icons/theme/wishlist-inactive.svg"

import { Container } from "../Styled/Container"
import { FormInputWrapper } from "../Styled/Form"
import { P, H5 } from "../Styled/Text"
import { PrimaryButton, TextButton } from "../Styled/Button"

export const StyledContainer = tw(Container)`max-w-lg mb-8`
export const SharedContainer = tw(Container)`max-w-lg mb-8 md:mb-10`
export const Border = tw.div`border-b border-grey mb-8 md:mb-12`

export const Controls = tw.div`flex items-center justify-between -mx-0-6`
export const Control = styled.div`
  ${tw`flex-1 px-0-6`}
  ${props => (props.hiddenSm ? tw`hidden md:block` : ``)}
`

export const Sup = tw.sup`text-xxs ml-0-4 mb-0-2`
export const SavedItems = tw.div`flex flex-wrap -m-0-6`
export const SharedItems = tw.div`flex flex-wrap -mx-0-6 -my-3`
export const EmptyContainer = styled.div`
  ${tw`w-full`}
  ${props => (props.condensed ? tw`md:max-w-62` : ``)}
`
export const EmptyRow = styled.div`
  ${tw`max-w-33-2 mx-auto flex flex-col items-center px-0-8`}
  ${props => (!props.condensed ? tw`pt-8` : ``)}
`
export const EmptyRowButton = tw(PrimaryButton)`mb-1-2`
export const EmptyRowHeading = tw(H5)`mb-2-6`
export const EmptyRowText = tw(P)`mb-2-6`

export const ShareHeading = tw(H5)`mt-5 mb-3-8`
export const ShareInput = tw(FormInputWrapper)`w-full max-w-26`
export const ShareNetworks = tw.div`flex items-center justify-center mt-1-6 mb-5`
export const ShareNetwork = tw.a`block mx-1-6 text-dark hover:text-grey-darker transition-all duration-200`
export const IconFacebook = tw(FacebookSvg)`block h-1-6`
export const IconTwitter = tw(TwitterSvg)`block h-1-6`
export const IconPinterest = tw(PinterestSvg)`block h-1-6`
export const IconShare = tw(ShareSvg)`block w-2-4 h-2-4`
export const ShareButton = styled(TextButton)`
  ${props => (props.hiddenLg ? tw`block w-full text-center md:hidden mt-1` : ``)}
`

export const Card = styled.div`
  ${tw`w-full px-0-6 py-3`}
  ${props => (props.condensed ? tw`md:w-1/4` : tw`md:w-1/3`)}
`
export const Content = tw.div`relative`
export const Details = tw.div`w-full pt-1-2 pb-4`
export const DetailsWrapper = tw.div`block w-full focus:outline-none`
export const StyledTextButton = tw(TextButton)`my-1`

export const SavedCounter = tw(Link)`flex items-center justify-center focus:outline-none`
export const IconSaved = tw(SavedSvg)`block w-2 h-2`
export const AccountItems = tw.span`text-xxs font-medium`

export const Button = tw.button`block focus:outline-none mb-1`
export const IconActive = tw(ActiveSvg)`block w-2-4 h-2-4 pointer-events-none`
export const IconInactive = tw(InactiveSvg)`block w-2-4 h-2-4 pointer-events-none`

export const PinItButton = tw.a`cursor-pointer block w-2-4 h-2-4 text-inherit focus:outline-none`
export const PinItButtonIcon = tw(PinterestSvg)`block w-2 h-2 m-auto text-inherit`
