const stage = process.env.GATSBY_STAGE || "consumer"

module.exports = {
  app: {
    title: "Kyha",
    description: "Wedding Dresses designed and made in Melbourne.",
    url: "https://kyhastudios.com",
    themeColor: "#000000",
    themeBackground: "#ffff04",
    themeDisplay: "standalone",
    themeIcon: "static/images/kyha-favicon.png",
  },
  stores: {
    ...(stage === "consumer"
      ? {
          "onedaybridal-au": {
            siteLocation: "AU",
            siteCountries: "AU,CA",
            siteCurrency: "AUD",
            shopName: "onedaybridal-au",
            shopDomain: "checkout-au.kyhastudios.com",
            accessToken: "638a2f4b0f310de856d603d03d29bdf9",
            adminAccessToken: "2127a08f8cdb00fe4895c15982372c89",
            shopifyPlus: true,
            sanityDataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
            searchIndex: "onedaybridal-au-search-v2",
          },
          "onedaybridal-us": {
            siteLocation: "US",
            siteCountries:
              "AG,AI,AR,AW,BB,BL,BM,BO,BQ,BR,BS,BZ,CL,CO,CR,CU,CW,DM,DO,EC,FK,GD,GF,GL,GP,GT,GY,HN,HT,JM,KN,KY,LC,MF,MQ,MS,MX,NI,PA,PE,PM,PR,PY,SR,SV,SX,TC,TT,US,UY,VC,VE,VG,VI",
            siteCurrency: "USD",
            shopName: "onedaybridal-us",
            shopDomain: "checkout-us.kyhastudios.com",
            accessToken: "ee79286318758e8aa12bc1c4e845f607",
            adminAccessToken: "18afa4722923d36f3ff59569ebcc42b7",
            shopifyPlus: true,
            sanityDataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
            searchIndex: "onedaybridal-us-search-v2",
          },
        }
      : {
          "kyha-wholesale": {
            siteLocation: "AU",
            siteCountries: "AU",
            siteCurrency: "AUD",
            shopName: "kyha-wholesale",
            shopDomain: "checkout.wholesale.kyhastudios.com",
            accessToken: "9bab0dabb10167ca4d50c521493eab14",
            adminAccessToken: "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",
            shopifyPlus: true,
            sanityDataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
            searchIndex: "kyha-wholesale-primary",
            wholesale: true,
          },
          "kyha-us-wholesale": {
            siteLocation: "US",
            siteCountries:
              "AG,AI,AR,AW,BB,BL,BM,BO,BQ,BR,BS,BZ,CL,CO,CR,CU,CW,DM,DO,EC,FK,GD,GF,GL,GP,GT,GY,HN,HT,JM,KN,KY,LC,MF,MQ,MS,MX,NI,PA,PE,PM,PR,PY,SR,SV,SX,TC,TT,US,UY,VC,VE,VG,VI",
            siteCurrency: "USD",
            shopName: "kyha-us-wholesale",
            shopDomain: "checkout-us.wholesale.kyhastudios.com",
            accessToken: "5d263576f087ef5dab764636caa22c8b",
            adminAccessToken: "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",
            shopifyPlus: true,
            sanityDataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
            searchIndex: "kyha-us-wholesale-primary",
            wholesale: true,
          },
        }),
  },
  services: {
    shopify: {
      apiVersion: "2022-10",
      defaultShopName: process.env.GATSBY_SHOPIFY_DEFAULT_SHOP,
    },
    sanity: {
      projectId: "nyemd8yo",
      token:
        "skAqbEChOEY1Od6RvxMmrGiG4ayBkxYAYjOYvYqXdinnazJjawof5jUIRl5mT3NBvNkSmC2iRnHQIpmphRKAz62K0mS90oXOcYlOYD8KxDEX7R0dTde5eY4Io1YJZZ7Ayz9hJ4m8psScTrLyEMtkpA1GZRniOZGxhWRltZm8xMoG8ijhU8bI",
      dataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
    },
    functions: {
      endpoint: "https://api.kyhastudios.com",
    },
    firebase: {
      region: "us-central1",
      apiKey: "AIzaSyCuRtJkT_qTQUs-5_cxg16fqlXsc5AocBc",
      authDomain: "kyha-website.firebaseapp.com",
      databaseURL: "https://kyha-website.firebaseio.com",
      projectId: "kyha-website",
      storageBucket: "kyha-website.appspot.com",
      messagingSenderId: "1079415411216",
      appId: "1:1079415411216:web:8e54e41bc0abff90d1b095",
    },
    location: {
      serviceUrl: "https://ipapi.co/json/?key=0Ll7MEifUPfQ0U2hiak1HyO7bw0KBY4P3deCexkNXVIaVEP700",
      countryFieldKey: "country",
      forceRegion: true,
    },
    reactify: {
      searchUrl: "https://api.search.reactify.app/",
      searchConfig: "https://config.search.reactify.app/?shop=kyha-wholesale.myshopify.com",
      searchFieldKey: "q",
      searchFieldSelector: "#q-downshift-input",
    },
    googleMaps: {
      apiKey: "AIzaSyCuRtJkT_qTQUs-5_cxg16fqlXsc5AocBc",
    },
    klaviyo: {
      apiKey: "JwtGaE",
    },
    onedaypay: {
      baseUrl: "https://pay.kyhastudios.com",
    },
  },
  queries: {
    products: {
      query: "allSanityProduct(filter: { shopify: { shopifyDeleted: { ne: true } } }) { edges { node { tags, shopify { handle: shopifyHandle } } } }",
      template: "templates/product.tsx",
      hidden: "hidden",
      parentTagPrefix: "parent",
      siblingTagPrefix: "colours",
      devPageLimit: 0,
    },
    collections: {
      query:
        "allSanityCollection(filter: { shopify: { shopifyDeleted: { ne: true } } }) { edges { node { shopify { handle: shopifyHandle } parent: parentCollection { shopify { handle: shopifyHandle } parent: parentCollection { shopify { handle: shopifyHandle } } } } } }",
      template: "templates/collection.tsx",
      hidden: "hidden",
      devPageLimit: 0,
    },
    articles: {
      query: "allSanityArticle { edges { node { _id slug { current } } } }",
      template: "templates/article.tsx",
      devPageLimit: 0,
    },
    stores: {
      query: "allSanityStore { edges { node { _id slug { current } } } }",
      template: "templates/store.tsx",
      devPageLimit: 0,
    },
    flexible: {
      query: "allSanityFlexiblePage { edges { node { _id slug { current } } } }",
      template: "templates/flexible.tsx",
    },
    policies: {
      query: "allSanityPolicyPage { edges { node { _id slug { current } } } }",
      template: "templates/policy.tsx",
    },
    payments: {
      checkout: "templates/checkout.tsx",
      charge: "templates/charge.tsx",
    },
    redirects: {
      query: "allSanityRedirect { edges { node { source type destination } } }",
      devPageLimit: 0,
    },
    robots: {
      query: "allSanitySettingRobots { nodes { content { code } } }",
    },
  },
  settings: {
    keys: {
      shopify: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:shopify`,
      country: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:country`,
      location: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:location`,
      measuringUnits: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:measuring`,
      recentProducts: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:recent`,
      searchFilters: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:searchFilters`,
      searchOrigin: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:searchOrigin`,
      checkout: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:checkout`,
      discounts: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:discounts`,
      customer: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:customer`,
      wishlist: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:wishlist`,
      announcement: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:announcement`,
      maintenance: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:maintenance`,
      password: `${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}:password`,
    },
    params: {
      checkoutID: "id",
      checkout: "checkout_url",
      checkoutRef: "ref",
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      country: "country",
      range: "range",
      search: "q",
      state: "state",
      stores: "location",
      variant: "variant",
      wishlist: "id",
    },
    pinterest: {
      href: "https://www.pinterest.com/pin/create/button/",
      dataPinDo: "buttonPin",
      dataPinCustom: "true",
    },
    products: {
      purchaseTag: "purchase: ",
      attributesTag: "customised:",
      currencyHandle: "currency",
      delivery: {
        fields: [
          {
            label: "DD",
            maxchars: 2,
            name: "day",
          },
          {
            label: "MM",
            maxchars: 2,
            name: "month",
          },
          {
            label: "YYYY",
            maxchars: 4,
            name: "year",
          },
        ],
        rush: {
          min: 12,
          max: 15,
          handle: "rush-fee",
        },
        weddingTag: "custom:wedding:",
        timelineTag: "timeline:",
        timelines: [
          {
            label: "months",
            threshold: 30,
          },
          {
            label: "weeks",
            threshold: 7,
          },
          {
            label: "days",
            threshold: 1,
          },
        ],
      },
      wholesale: {
        fields: [
          {
            id: "wholesaleOrderReference",
            maxLength: 200,
            showLabel: true,
            type: "text",
          },
          {
            id: "wholesaleShippingDate",
            maxLength: 10,
            placeholder: "DD-MM-YYYY",
            showLabel: true,
            type: "date",
          },
          {
            id: "wholesaleWeddingDate",
            maxLength: 10,
            placeholder: "DD-MM-YYYY",
            showLabel: true,
            type: "date",
          },
          {
            id: "wholesaleCustomisations",
            maxLength: 1000,
            showLabel: true,
            type: "textarea",
          },
        ],
        tags: {
          moqEnabled: "MOQ:enabled",
          moqQuantity: "MOQ:quantity:",
        },
        disableCustomisationsFor: "Accessories",
      },
      giftCards: {
        attributes: [
          { label: "To", maxchars: 50, type: "text" },
          { label: "From", maxchars: 50, type: "text" },
          {
            label: "Message",
            maxchars: 120,
            placeholder: "Enter your message here (120 character maximum)",
            type: "textarea",
          },
        ],
        option: {
          name: "Type",
          values: [
            {
              label: "Digital",
              handle: "gift-certificate-digital",
            },
            {
              label: "Physical",
              handle: "gift-certificate-physical",
            },
          ],
        },
        type: "Gift Card",
      },
      giftWrappingHandle: "gift-wrapping",
      hiddenTag: "hidden",
      lowStockThreshold: 3,
      splitSizing: {
        sizeOptionName: "size",
        sizeOptionValue: "split",
        dimentions: ["bust", "waist", "hips"],
      },
      stockTag: "stock:",
    },
    lists: [
      {
        key: "products",
        title: "Products",
      },
      {
        key: "inspiration",
        title: "Inspiration",
      },
    ],
    socialMedia: {
      accounts: {
        facebook: "https://www.facebook.com/kyhastudiosau",
        instagram: "https://www.instagram.com/onedaybridal",
        pinterest: "https://www.pinterest.com.au/KYHAStudios/",
        twitter: "https://twitter.com/kyhaoneday",
      },
      og: {
        language: "en_AU",
        siteName: "Kyha",
        twitterUser: "@kyhaoneday",
      },
      sharing: {
        facebook: "https://www.facebook.com/sharer/sharer.php?u=",
        pinterest: "http://pinterest.com/pin/create/button/?url=",
        twitter: "https://twitter.com/intent/tweet?url=",
      },
    },
    tracking: {
      ga: "UA-54880773-1",
      gtm: "GTM-KN4J6HS",
      pixelID1: "119006676100458",
      pixelID2: "149406492406283",
      pixelIDUS: "2072924112938942",
      fbAppID: "149406492406283",
      googleSiteVerify: "HQHuk0ep3dYBHvZeZDs8fdgAV8Ca7o_9i9YgZYRn1_Y",
    },
    routes: {
      HOMEPAGE: "/",
      ACCOUNT: "/account",
      ACCOUNT_ACTIVATE: "/account/activate",
      ACCOUNT_ADDRESSES: "/account/addresses",
      ACCOUNT_LOGIN: "/account/login",
      ACCOUNT_ORDERS: "/account/orders",
      ACCOUNT_CUSTOM_PAYMENT: "/account/onedaypay/overall/view",
      ACCOUNT_FORGOT_PASSWORD: "/account/forgot",
      ACCOUNT_RESET_PASSWORD: "/account/reset",
      ACCOUNT_SAVED: "/account/saved",
      ACCOUNT_SETTINGS: "/account/preferences",
      ADMIN: "/admin",
      ARTICLE: "/stories",
      BRIDES: "/brides",
      CART: "/cart",
      CART_LOGIN: "/cart/login",
      CHECKOUT: "/account/login/loading",
      COLLECTION: "/collections",
      POLICY: "/policies",
      PAGE: "",
      PASSWORD: "/password",
      PRODUCT: "/products",
      SAVED: "/saved",
      SAVED_SHARE: "/saved/share",
      SEARCH: "/search",
      STORE: "/stores",
      PAYMENT_CHECKOUT: "/onedaypay/:orderId/checkout",
      PAYMENT_CHARGE: "/onedaypay/:orderId/charge",
    },
    defaultRedirects: [
      {
        source: "/collection",
        destination: "/collections",
        type: 301,
      },
      {
        source: "/checkout",
        destination: "/cart",
        type: 301,
      },
    ],
    clientPaths: ["/account/onedaypay/*"],
    sitemapExclusions: [
      "/dev-404-page",
      "/404",
      "/404.html",
      "/offline-plugin-app-shell-fallback",
      "/giftcard",
      "/password",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/onedaypay",
      "/account/addresses",
      "/account/preferences",
      "/account/saved",
      "/search",
      "/saved",
      "/saved/share",
      "/cart",
      "/cart/login",
    ],
  },
}
