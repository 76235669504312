import React, { useState, useRef, useEffect, useMemo } from "react"

import { useGlobal } from "../hooks/useGlobal"

export const CheckoutContext = React.createContext({})

export const CheckoutProvider = ({ children }) => {
  const { handleGlobalGroupReady } = useGlobal()
  const [checkout, setCheckout] = useState(false)

  const prevCheckout = useRef(checkout)

  // save previous checkout in the prevCheckout ref
  useEffect(() => {
    if (!prevCheckout.current) {
      prevCheckout.current = checkout
    }
  }, [checkout])

  // compare previous checkout and current checkout, if previous is false and current is not false, then set the checkout ready to true
  useEffect(() => {
    if (prevCheckout.current === false && checkout) {
      handleGlobalGroupReady("checkout")
    }
  }, [prevCheckout, checkout])

  const providerValue = useMemo(
    () => ({
      checkout,
      setCheckout,
    }), [checkout, setCheckout])

  return <CheckoutContext.Provider value={providerValue}>{children}</CheckoutContext.Provider>
}
