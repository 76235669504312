import React from "react"

import { withNavigationSearchSuggestions } from "./withNavigationSearchSuggestions"
import { SearchItem } from "../../../Search/Item/SearchItem"
import { Suggestions } from "../NavigationSearchStyles"
import { ResultsRow } from "../../../Search/SearchStyles"

interface Props {
  setActive: any
  items?: Array<any>
}

export const NavigationSearchSuggestions = withNavigationSearchSuggestions(({ items = [], setActive }: Props) => (
  <Suggestions>
    <ResultsRow verticalSpacing>
      {items?.length > 0 &&
        items?.map((product, index) => (
          <SearchItem
            key={product.id}
            index={index}
            list={`Search Suggestions`}
            product={product}
            onClick={() => setActive(false, "keepStorage")}
            verticalSpacing
            width={`1/2 md:1/4`}
          />
        ))}
    </ResultsRow>
  </Suggestions>
))
