import React from "react"

import { useSettings } from "../../hooks/useSettings"

export const withCopyright = Component => ({ name = `Copyright` }) => {
  const { organisation } = useSettings()
  const date = new Date()

  Component.displayName = name
  return <Component date={date} title={organisation?.titleShort} />
}
