import { useCallback, useContext, useState, useEffect, useMemo } from "react"

import { useApp } from "./useApp"
import { useCart } from "./useCart"
import { useCore } from "./useCore"
import { useQueries } from "./useQueries"
import { useShopify } from "./useShopify"
import { useCustomerContext, useCustomerLogout } from "./useCustomer"
import { useShop } from "./useShop"
import { CheckoutContext } from "../providers/checkout"
import { useLocation } from "./useLocation"
import { useFunctions } from "./useFunctions"

export const useCheckoutContext = () => {
  const checkoutData = useContext(CheckoutContext)
  return { ...checkoutData }
}

export const useCheckout = () => {
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { storage, isBrowser },
  } = useCore()
  const { customer } = useCustomerContext()
  const { checkoutMultipass } = useFunctions()
  const {
    mutations: {
      CHECKOUT_CREATE,
      CHECKOUT_ATTRIBUTES_UPDATE,
      CHECKOUT_SHIPPING_ADDRESS_UPDATE,
      CHECKOUT_EMAIL_UPDATE,
      CHECKOUT_DISCOUNT_APPLY,
      CHECKOUT_GIFTCARDS_APPEND,
      CHECKOUT_CUSTOMER_ASSOCIATE,
    },
    queries: { GET_CHECKOUT, GET_SHIPPING_RATES, GET_PRODUCTS_BY_HANDLE },
  } = useQueries()
  const { excludeGroupedAndWrappingItems } = useCart()

  const { checkout, setCheckout: saveCheckout } = useCheckoutContext()

  const { checkoutNormaliser, useMutation, useQuery, client } = useShopify()
  const { logoutCustomer } = useCustomerLogout()

  const { getShop } = useShop()
  const { countryCode, currencyCode, shopifyStore, shopifyStoreDomain, storeConfig } = useLocation()
  const [checkoutId, setCheckoutId] = useState(checkout?.id || storage.get(keys?.checkout))
  useEffect(() => setCheckoutId(checkout?.id || storage.get(keys?.checkout)), [checkout, keys?.checkout])

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkoutAttributeUpdate] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)
  const [checkoutCustomerAssociate] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE)
  const [checkoutShippingAddressUpdate] = useMutation(CHECKOUT_SHIPPING_ADDRESS_UPDATE)
  const [checkoutEmailUpdate] = useMutation(CHECKOUT_EMAIL_UPDATE)
  const [checkoutDiscountApply] = useMutation(CHECKOUT_DISCOUNT_APPLY)
  const [checkoutGiftcardAppend] = useMutation(CHECKOUT_GIFTCARDS_APPEND)

  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const { refetch: getShippingRatesQuery } = useQuery(GET_SHIPPING_RATES, { fetchPolicy: "no-cache", skip: true })

  const getCheckout = useCallback(async () => {
    try {
      if (checkoutId) {
        const {
          data: { node: checkout },
        } = await getCheckoutQuery({ countryCode, checkoutId })

        return checkout
      }
      return false
    } catch (error) {
      console.error(error)
    }
  }, [countryCode, checkoutId, getCheckoutQuery, keys?.checkout])

  const setCheckout = useCallback(
    checkout => {
      try {
        saveCheckout(checkoutNormaliser(checkout))
        storage.set(keys?.checkout, checkout?.id)
      } catch (error) {
        console.error(error)
      }
    },
    [saveCheckout]
  )

  const createCheckout = useCallback(
    async (country = countryCode, forceNew = false) => {

      try {
        const existingCheckout = !forceNew && (await getCheckout())

        if (forceNew || !existingCheckout?.id || existingCheckout?.completedAt !== null || Object.keys(existingCheckout).length < 1) {
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate({
            variables: { input: { buyerIdentity: { countryCode: country } }, countryCode: country },
          })

          if (checkout) {
            setCheckout(checkout)
          }
        } else {
          setCheckout(existingCheckout)
        }
        getShop()
      } catch (error) {
        storage.remove(keys?.checkout)
        console.error(error)
      }
    },
    [getCheckout, setCheckout, checkoutCreate, getShop, currencyCode]
  )

  const updateAttributes = useCallback(
    async input => {
      const {
        data: { checkoutAttributesUpdateV2: data },
      } = await checkoutAttributeUpdate({
        variables: { checkoutId, input, countryCode },
      })

      setCheckout(data?.checkout)
    },
    [checkoutAttributeUpdate, setCheckout]
  )

  const updateCustomer = useCallback(
    async customerAccessToken => {
      try {
        const {
          data: { checkoutCustomerAssociateV2: data },
        } = await checkoutCustomerAssociate({
          variables: { checkoutId, customerAccessToken, countryCode },
        })
        setCheckout(data?.checkout)
      } catch(error) {
        console.error(error)
      }
    },
    [checkoutId, checkoutCustomerAssociate, setCheckout, countryCode]
  )

  const updateShippingAddress = useCallback(
    async input => {
      const {
        data: { checkoutShippingAddressUpdateV2: data },
      } = await checkoutShippingAddressUpdate({
        variables: {
          shippingAddress: {
            firstName: input?.firstName,
            lastName: input?.lastName,
            address1: input?.address1,
            address2: input?.address2,
            city: input?.city,
            country: input?.country,
            phone: input?.phone,
            province: input?.province,
            zip: input?.zip,
          },
          checkoutId,
          countryCode
        },
      })
      setCheckout(data?.checkout)
    },
    [checkoutShippingAddressUpdate, setCheckout]
  )

  const updateEmail = useCallback(
    async email => {
      const {
        data: { checkoutEmailUpdateV2: data },
      } = await checkoutEmailUpdate({
        variables: { email, checkoutId, countryCode },
      })
      setCheckout(data?.checkout)
    },
    [checkoutEmailUpdate, setCheckout]
  )

  const applyDiscountCode = useCallback(
    async discountCode => {
      const {
        data: { checkoutDiscountCodeApplyV2: data },
      } = await checkoutDiscountApply({
        variables: { discountCode, checkoutId, countryCode },
      })

      if (!data.checkoutUserErrors.length) {
        storage.set(
          keys?.discounts,
          [...(storage.get(keys?.discounts) || []), discountCode].filter((value, index, self) => self.indexOf(value) === index)
        )
        setCheckout(data?.checkout)
      } else {
        return data
      }
    },
    [checkoutDiscountApply, setCheckout]
  )

  const applyGiftCardCode = useCallback(
    async giftCardCode => {
      const {
        data: { checkoutGiftCardsAppend: data },
      } = await checkoutGiftcardAppend({
        variables: { giftCardCodes: [giftCardCode], checkoutId, countryCode },
      })

      if (!data.checkoutUserErrors.length) {
        setCheckout(data.checkout)
        return this
      } else {
        return data
      }
    },
    [checkoutGiftcardAppend, setCheckout]
  )

  const getShippingRates = useCallback(async () => {
    const {
      data: { node: checkout },
    } = await getShippingRatesQuery({ countryCode, id: checkoutId })
    setCheckout(checkout)
    return checkout
  }, [getShippingRatesQuery, setCheckout])

  const applyDiscounts = useCallback(async () => {
    const discountCodes = storage.get(keys?.discounts) || []
    for (const discountCode of discountCodes) {
      await applyDiscountCode(discountCode)
    }
  }, [applyDiscountCode])

  const duplicateCheckout = useCallback(
    async (changes = {}) => {
      const { currencyCode, customAttributes, lineItems, note, email, shippingAddress } = checkout
      const discounts = storage.get(keys?.discounts) || []

      const {
        data: { checkoutCreate: data },
      } = await checkoutCreate({
        variables: {
          countryCode: countryCode,
          input: {
            ...(email && { email }),
            ...(note && { note }),
            ...(customAttributes && {
              customAttributes:
                customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })) || [],
            }),
            presentmentCurrencyCode: currencyCode,
            buyerIdentity: {
              countryCode: countryCode,
            },
            lineItems:
              lineItems?.map(item => ({
                variantId: item?.variant.id,
                quantity: item?.quantity || 1,
                customAttributes:
                  item?.customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              })) || [],
            shippingAddress: shippingAddress
              ? {
                address1: shippingAddress.address1,
                address2: shippingAddress.address2,
                city: shippingAddress.city,
                company: shippingAddress.company,
                country: shippingAddress.country,
                firstName: shippingAddress.firstName,
                lastName: shippingAddress.lastName,
                phone: shippingAddress.phone,
                province: shippingAddress.province,
                zip: shippingAddress.zip,
              }
              : undefined,
            ...changes,
          },
        },
      })
      if (!discounts.length) setCheckout(data?.checkout)
      if (discounts.length) applyDiscounts()
    },
    [checkout, checkoutCreate, setCheckout, applyDiscounts]
  )

  const migrateCheckout = useCallback(
    async (changes = {}) => {
      const { lineItems, note, email, customAttributes, shippingAddress } = checkout
      const discounts = storage.get(keys?.discounts) || []

      if (lineItems?.length) {
        const mappedLineItems =
          lineItems?.map(item => ({
            handle: item?.variant?.product?.handle,
            sku: item?.variant?.sku,
            quantity: item?.quantity,
            customAttributes: item?.customAttributes,
          })) || []

        const { data: matchedProducts } = await client.query({
          query: GET_PRODUCTS_BY_HANDLE(mappedLineItems.map(product => product?.handle)),
          variables: {
            firstCollections: 0,
            firstImages: 0,
            firstMedia: 0,
            firstMetafields: 0,
            firstVariants: 100,
          },
        })

        const migratedLineItems =
          mappedLineItems.map(lineItem => ({
            variantId: matchedProducts[`product${lineItem?.handle?.replace(/-/g, "")}`]?.variants?.edges
              ?.filter(({ node }) => node?.sku === lineItem?.sku)
              .map(({ node }) => node?.id)[0],
            quantity: lineItem?.quantity,
            customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
              key,
              value,
            })),
          })) || []

        const {
          data: { checkoutCreate: data },
        } = await checkoutCreate({
          variables: {
            countryCode: countryCode,
            input: {
              ...(email && { email }),
              ...(note && { note }),
              ...(customAttributes && {
                customAttributes:
                  customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              }),
              buyerIdentity: {
                countryCode: countryCode,
              },
              presentmentCurrencyCode: currencyCode,
              lineItems: migratedLineItems,
              shippingAddress: shippingAddress || undefined,
              ...changes,
            },
          },
        })
        if (!discounts.length) setCheckout(data?.checkout)
        if (discounts.length) applyDiscounts()
        getShop()
        logoutCustomer()
      } else {

        createCheckout(countryCode, true)

      }
    },
    [checkout, currencyCode, createCheckout, checkoutCreate, setCheckout, applyDiscounts, logoutCustomer, getShop, client, GET_PRODUCTS_BY_HANDLE]
  )

  const updateCurrency = useCallback(
    async (presentmentCurrencyCode = currencyCode) => {
      await duplicateCheckout({
        presentmentCurrencyCode: presentmentCurrencyCode,
      })
      getShop()
    },
    [currencyCode, duplicateCheckout, getShop]
  )

  const count =
    excludeGroupedAndWrappingItems(checkout?.lineItems || [])?.reduce(
      (count, lineItem, i) => (i ? count + parseInt(lineItem.quantity) : parseInt(lineItem.quantity)),
      0
    ) || 0

  const checkoutUrl = useMemo(() => {
    return checkout.webUrl ? checkout?.webUrl?.replace(`${shopifyStore}.myshopify.com`, shopifyStoreDomain) : ""
  }, [checkout, shopifyStore, shopifyStoreDomain])

  const gotoCheckout = useCallback(
    async (e, email) => {
      e?.preventDefault()

      if (!isBrowser) return null

      if (customer?.email) {
        try {
          const response = await checkoutMultipass(
            customer?.email || email,
            checkout.id,
            checkout.webUrl,
          )

          const url = response.status !== "error" && response.body.includes("https://") ? response.body : checkout.webUrl

          window.location.href = url
        } catch (e) {
          window.location.href = checkout?.webUrl
          console.error(e);
        }
      } else {
        window.location = checkout?.webUrl
      }
    },
    [checkoutMultipass, checkout, customer, isBrowser]
  )
  

  return {
    checkout,
    checkoutUrl,
    countryCode,
    currencyCode,
    createCheckout,
    getCheckout,
    gotoCheckout,
    setCheckout,
    migrateCheckout,
    updateCurrency,
    updateAttributes,
    updateShippingAddress,
    updateEmail,
    updateCustomer,
    applyDiscountCode,
    applyGiftCardCode,
    getShippingRates,
    count,
  }
}
