import styled from "@emotion/styled"
import tw from "twin.macro"

export const Table = tw.div`overflow-y-hidden overflow-x-auto min-w-full`
export const Row = styled.div`
  ${tw`flex text-sm transition-all duration-200 duration-100`} ${props => (props.highlighted ? tw`md:bg-grey` : ``)}
`
export const Column = styled.div`
  ${tw`relative flex items-center w-full overflow-hidden block truncate text-center flex-1 py-0-8 px-0-4 transition-all duration-200 duration-100 min-w-9-6 border-b border-grey`}
  ${props => (props.firstRow ? tw`border-t` : tw`justify-center text-center`)}
  ${props => (props.first ? tw`justify-start text-left` : tw`justify-center text-center`)}
  ${props => (props.noHighlight ? tw`md:bg-light` : props.highlighted === `both` ? tw`md:bg-grey-mid` : props.highlighted ? tw`md:bg-grey` : ``)}
`
