import { Link } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import tw from "twin.macro"

import ArrowRight from "static/icons/theme/arrow-right.svg"
import Icon from "static/icons/theme/close.svg"

export const Item = styled.li`
  ${tw`whitespace-no-wrap border-b border-grey py-1-6 px-0-8`} ${props => (props.index ? `` : tw`border-t`)}
`
export const SubList = styled.ul`
  ${tw`mt-1-2`} ${props => (props.active ? tw`block` : tw`hidden`)}
`
const SubLink = tw`text-left uppercase font-sans font-light tracking-tight leading-loose mt-1-2 text-md hover:italic`

export const StyledLink = styled(Link)`
  ${tw`block no-underline text-left uppercase tracking-wider font-medium text-xms transform transition-all duration-200`} ${props =>
    props.active ? tw`duration-500 delay-200 translate-x-0` : tw`-translate-x-12`} ${props => (props.active ? tw`opacity-100` : tw`opacity-0`)}
`
export const StyledSubLink = styled(StyledLink)`
  ${tw`flex items-center justify-start`} ${SubLink} ${props => (props.inset ? tw`ml-1-6` : tw`ml-0`)}
`
export const StyledSubButton = styled.button`
  ${tw`flex items-center justify-start focus:outline-none transform transition-all duration-200`} ${SubLink} ${props =>
    props.active ? tw`duration-500 delay-200 translate-x-0` : tw`-translate-x-12`} ${props => (props.active ? tw`opacity-100` : tw`opacity-0`)}
`
export const SubButtonText = tw.span`block ml-0-8 mt-0-2`
export const IconArrowRight = styled(ArrowRight)`
  ${tw`block w-0-8 h-0-8 transform transition-all duration-200`} ${props => (props.active ? tw`rotate-90` : tw`rotate-0`)}
`

export const MegaItem = tw.li`whitespace-no-wrap mr-2`
export const MegaSubList = tw.ul`mt-1-2`

export const MegaStyledLink = styled(Link)`
  ${tw`block no-underline text-left uppercase tracking-wider font-medium text-xs transform transition-all duration-200`} ${props =>
    props.active ? tw`translate-x-0 duration-500 delay-200` : tw`-translate-x-12`} ${props =>
    props.active ? tw`opacity-100` : tw`opacity-0`} ${props => (props.index ? tw`mt-4` : ``)}
`

export const MegaStyledSubLink = tw(StyledLink)`font-sans font-light tracking-tight leading-loose mt-0-2 text-md hover:italic`

export const ListStyledItem = tw.li`whitespace-no-wrap mr-2 md:mr-1 lg:mr-2`
export const ListStyledLink = styled.button`
  ${tw`block no-underline text-left uppercase tracking-wider text-xxs font-medium focus:outline-none transition-all duration-200`}
  ${props => (props.inactive ? (props.as === `button` ? tw`text-dark hover:text-grey-darkest` : tw`text-grey-mid hover:text-dark`) : ``)}
`

export const Section = tw.nav`fixed md:static block w-auto md:w-1/3`
export const Nav = tw.div`hidden md:block w-full relative z-20`
export const List = tw.ul`flex list-none`

export const Close = styled.li`
  ${tw`hidden md:block px-1-2 h-1-5 -mt-0-3 ml-32 text-dark transition-all duration-200 cursor-pointer focus:outline-none`}
  ${props => (props.visible ? tw`visible opacity-100` : tw`invisible opacity-0`)}
`
export const StyledIcon = styled(Icon)`
  ${tw`block w-2 h-2`}
`
export const MegaList = styled.ul`
  ${css`
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
  ${tw`hidden md:block absolute inset-x-0 w-full px-4-2 pt-19-5 pb-2 top-4-2 bottom-0 max-h-full-vh overflow-x-hidden overflow-y-auto`}
`
export const DropdownListWrapper = styled.div`
  ${tw`block md:hidden absolute inset-0 flex flex-col items-start justify-start`}
`
export const DropdownList = styled.ul`
  ${tw`block w-full px-1-2 mt-7 flex-1 overflow-y-auto overflow-x-hidden`}
`
export const StyledButton = styled.button`
  ${tw`md:hidden flex items-center justify-center uppercase tracking-wider text-center text-xxs font-medium focus:outline-none transition-all duration-200 px-2 py-0-8`}
  ${props =>
    props.btnStyle === "light" ? tw`hover:bg-dark hover:text-light bg-grey text-dark` : tw`bg-dark text-light hover:bg-grey hover:text-dark`}
`
