import React, { useCallback, useMemo, useState, createContext } from "react"

export const AppContext = createContext({})

export const AppProvider = ({ children, config: siteConfig }) => {
  const [config] = useState(siteConfig || {})
  const [activeCart, setActiveCart] = useState(false)
  const [activeProduct, setActiveProduct] = useState(false)
  const [activeVariant, setActiveVariant] = useState(false)
  const [currency, setCurrency] = useState(null)
  const [terms, setTerms] = useState(``)
  const [invertedTheme, setInvertedTheme] = useState(false)
  const [transparentTheme, setTransparentTheme] = useState(false)
  const [notification, setNotification] = useState({})

  const updateActiveCart = useCallback(setActiveCart, [])
  const updateActiveProduct = useCallback(setActiveProduct, [])
  const updateActiveVariant = useCallback(setActiveVariant, [])
  const updateCurrency = useCallback(setCurrency, [])
  const updateTerms = useCallback(setTerms, [])
  const updateInvertedTheme = useCallback(setInvertedTheme, [])
  const updateTransparentTheme = useCallback(setTransparentTheme, [])

  const updateNotifications = (type, value) => setNotification(prevState => ({ ...prevState, [type]: value }))

  const providerValue = useMemo(
    () => ({
      config,
      activeCart,
      setActiveCart: updateActiveCart,
      activeProduct,
      setActiveProduct: updateActiveProduct,
      activeVariant,
      setActiveVariant: updateActiveVariant,
      currency,
      setCurrency: updateCurrency,
      terms,
      setTerms: updateTerms,
      invertedTheme,
      setInvertedTheme: updateInvertedTheme,
      transparentTheme,
      setTransparentTheme: updateTransparentTheme,
      notification,
      updateNotifications,
    }),
    [
      config,
      activeCart,
      updateActiveCart,
      currency,
      updateCurrency,
      terms,
      updateTerms,
      invertedTheme,
      updateInvertedTheme,
      transparentTheme,
      updateTransparentTheme,
      notification,
      updateNotifications,
    ]
  )

  return <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
}

export const withApp = Component => props => <AppContext.Consumer>{values => <Component {...props} layout={values} />}</AppContext.Consumer>
