import { useContext } from "react"
import { GlobalContext } from "../providers/global"

export const useGlobalContext = () => {
  const GlobalData = useContext(GlobalContext)
  return { ...GlobalData }
}

export const useGlobal = () => {
  const { setReadyGroup } = useGlobalContext()
  const handleGlobalGroupReady = groupName => {
    setReadyGroup(prevState => ({
      ...prevState,
      [groupName]: true,
    }))
  }
  return { handleGlobalGroupReady }
}
