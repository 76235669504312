import React from "react"

import { useShopify } from "../../hooks/useShopify"

export const withImage = Component => ({ name = `Image`, alt, cover, inline, maxV, onClick, ratio, src: imageSrc, gradient }) => {
  const { imageUrl, imageSrcSets } = useShopify()
  const sizes = [1, 500, 1000, 1500, 2000, 2500]

  const fluid = imageSrc?.aspectRatio ? imageSrc : imageSrc?.localFile?.childImageSharp?.fluid || imageSrc?.fluid
  const source = fluid?.imageSrc || imageSrc?.src || imageSrc

  const src = typeof source === `string` && imageUrl(source, sizes[0])
  const srcSets = typeof source === `string` && imageSrcSets(source)

  Component.displayName = name
  return (
    <Component
      alt={alt}
      cover={cover}
      fluid={fluid}
      gradient={gradient}
      inline={inline}
      maxV={maxV}
      onClick={onClick}
      ratio={ratio}
      src={src}
      srcSets={srcSets}
    />
  )
}
