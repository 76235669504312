import * as braintreeWeb from "braintree-web"
import { useStaticQuery, graphql } from "gatsby"
import axios from "axios"
import * as Sentry from "@sentry/gatsby"

import config from "../../config.default"

import { useShopify } from "./useShopify"
import { useSanity } from "./useSanity"
import { useSentry } from "./useSentry"
import { useCheckoutContext } from "./useCheckout"

export const usePaymentData = () => {
  const { textNormaliser } = useSanity()

  const { payments } = useStaticQuery(
    graphql`
      query PaymentData {
        payments: sanityOnedayPayData {
          customPaymentEnabled
          customPaymentDefaultPlan
          customPaymentUsers
          customPaymentDescription: _rawCustomPaymentDescription(resolveReferences: { maxDepth: 10 })
          customPaymentPolicy: _rawCustomPaymentPolicy(resolveReferences: { maxDepth: 10 })
        }
      }
    `
  )

  return {
    ...payments,
    customPaymentDescription: payments?.customPaymentDescription ? textNormaliser(payments.customPaymentDescription) : null,
    customPaymentPolicy: payments?.customPaymentPolicy ? textNormaliser(payments.customPaymentPolicy) : null,
  }
}

export const usePaymentPrices = () => {
  const { customPaymentDefaultPlan } = usePaymentData()
  const { formatMoney } = useShopify()
  const { checkout } = useCheckoutContext()

  const usePaymentTerms = (product, variant) => {
    const price = variant?.priceV2?.local
    const cents = variant?.priceV2?.amount

    const availableTagTerms = product?.tags?.filter(tag => tag.includes("payment-plan:")).map(t => Number(t.replace("payment-plan:", "")))
    const defaultPlan = Number(customPaymentDefaultPlan)
    let displayPlan = availableTagTerms?.length > 0 ? availableTagTerms[0] : 0

    // Check and use default plan that is set up in Sanity
    if (defaultPlan && availableTagTerms?.includes(defaultPlan)) {
      displayPlan = defaultPlan 
    }

    const terms = displayPlan > 0 && `or ${displayPlan} payments of ${formatMoney((parseFloat(cents) / displayPlan).toFixed(2), checkout?.currencyCode)} with `

    return price ? terms : null
  }

  return { usePaymentTerms }
}

export const getBraintreeToken = async (braintreeCustomerId, shopifyStore) => {
  const { sendSentryEvent } = useSentry()
  let response

  try {
    response = await axios.get(`${config.services.onedaypay.baseUrl}/api/gateway/client_token/${braintreeCustomerId ? braintreeCustomerId : ""}`, {
      headers: { "x-shopify-shop-domain": `${shopifyStore}.myshopify.com` },
    })
  } catch (err) {
    console.error(`[Braintree Token][ERROR]: ${err.message}`)
    sendSentryEvent({
      message: `[Braintree Token][${braintreeCustomerId}][ERROR]: ${err.message}`,
      tag: "get_braintree_token",
      level: Sentry.Severity.Error,
      shopifyStore,
      error: err,
    })
    throw new Error(`[Braintree Token][ERROR]: ${err.message}`)
  }

  if (response.data.status !== "success") {
    console.error(`[Braintree Token][ERROR]: ${response.data.body}`)
    sendSentryEvent({
      message: `[Braintree Token][${braintreeCustomerId}][ERROR]: ${response.data.body}`,
      tag: "get_braintree_token",
      level: Sentry.Severity.Error,
      shopifyStore,
      responsePayload: JSON.stringify(response.data),
    })
    throw new Error(`[Braintree Token][ERROR]: ${response.data.body}`)
  }

  return response.data.body
}

export const createBraintreeClient = async (braintreeCustomerId, shopifyStore) => {
  const braintreeCustomerToken = await getBraintreeToken(braintreeCustomerId, shopifyStore)

  return braintreeWeb.client.create({
    authorization: braintreeCustomerToken.clientToken,
  })
}

export const createPaypalCheckoutInstance = async braintreeClient => {
  return braintreeWeb.paypalCheckout.create({
    client: braintreeClient,
  })
}

export const handleCheckout = async (shopifyCustomerId, shopifyOrderId, planId, nonce, shopifyStore) => {
  const { sendSentryEvent } = useSentry()
  let response

  try {
    response = await axios.post(
      `${config.services.onedaypay.baseUrl}/api/gateway/checkout/${shopifyOrderId}`,
      {
        customerId: shopifyCustomerId,
        planId: planId,
        paymentMethodNonce: nonce,
      },
      {
        headers: { "x-shopify-shop-domain": `${shopifyStore}.myshopify.com` },
      }
    )
  } catch (err) {
    console.error(`[Handle Checkout][ERROR]: ${err.message}`)
    sendSentryEvent({
      message: `[Handle Checkout][${shopifyOrderId}][ERROR]: ${err.message}`,
      tag: "handle_checkout",
      level: Sentry.Severity.Error,
      shopifyStore,
      error: err,
    })
    throw new Error(err.message)
  }

  if (response.data.status !== "success") {
    console.error(`[Handle Checkout][ERROR]: ${response.data.body}`)
    sendSentryEvent({
      message: `[Handle Checkout][${shopifyOrderId}][ERROR]: ${response.data.body}`,
      tag: "handle_checkout",
      level: Sentry.Severity.Error,
      shopifyStore,
      responsePayload: JSON.stringify(response.data),
    })
    throw new Error(`${response.data.body}`)
  }

  return response.data.body
}

export const updatePaymentMethod = async (shopifyCustomerId, shopifyOrderId, nonce, shopifyStore) => {
  const { sendSentryEvent } = useSentry()
  let response

  try {
    response = await axios.put(
      `${config.services.onedaypay.baseUrl}/api/customers/${shopifyCustomerId}/payment-plans/${shopifyOrderId}`,
      {
        paymentMethodNonce: nonce,
      },
      {
        headers: { "x-shopify-shop-domain": `${shopifyStore}.myshopify.com` },
      }
    )
  } catch (err) {
    console.error(`[Update Payment method][ERROR]: ${err.message}`)
    sendSentryEvent({
      message: `[Update Payment method][${shopifyOrderId}][ERROR]: ${err.message}`,
      tag: "update_payment_method",
      level: Sentry.Severity.Error,
      shopifyStore,
      error: err,
    })
    throw new Error(err.message)
  }

  if (response.data.status !== "success") {
    console.error(`[Update Payment method][ERROR]: ${response.data.body}`)
    sendSentryEvent({
      message: `[Update Payment method][${shopifyOrderId}][ERROR]: ${response.data.body}`,
      tag: "update_payment_method",
      level: Sentry.Severity.Error,
      shopifyStore,
      responsePayload: `${response.data}`,
    })
    throw new Error(`[Update Payment method][ERROR]: ${response.data.body}`)
  }

  return response.data.body
}

export const handleCharge = async (shopifyCustomerId, shopifyOrderId, amount, nonce, paymentType, shopifyStore) => {
  const { sendSentryEvent } = useSentry()
  let response

  try {
    response = await axios.patch(
      `${config.services.onedaypay.baseUrl}/api/customers/${shopifyCustomerId}/payment-plans/${shopifyOrderId}`,
      {
        paymentAmount: amount,
        paymentMethodNonce: nonce,
        paymentType: paymentType,
      },
      {
        headers: { "x-shopify-shop-domain": `${shopifyStore}.myshopify.com` },
      }
    )
  } catch (err) {
    console.error(`[Handle Charge][ERROR]: ${err.message}`)
    sendSentryEvent({
      message: `[Handle Charge][${shopifyOrderId}][ERROR]: ${err.message}`,
      tag: "handle_charge",
      level: Sentry.Severity.Error,
      shopifyStore,
      error: err,
    })
    throw new Error(err.message)
  }

  if (response.data.status !== "success") {
    console.error(`[Handle Charge][ERROR]: ${response.data.body}`)
    sendSentryEvent({
      message: `[Handle Charge][${shopifyOrderId}][ERROR]: ${response.data.body}`,
      tag: "handle_charge",
      level: Sentry.Severity.Error,
      responsePayload: `${response.data}`,
    })
    throw new Error(`${response.data.body}`)
  }

  return response.data.body
}

export const loadCheckout = async (shopifyCustomerId, shopifyOrderId, shopifyStore) => {
  const { sendSentryEvent } = useSentry()
  let response

  try {
    response = await axios.get(`${config.services.onedaypay.baseUrl}/api/customers/${shopifyCustomerId}/payment-plans/${shopifyOrderId}`, {
      headers: { "x-shopify-shop-domain": `${shopifyStore}.myshopify.com` },
    })
  } catch (err) {
    console.error(`[Load Checkout][ERROR]: ${err.message}`)
    sendSentryEvent({
      message: `[Load Checkout][${shopifyOrderId}][ERROR]: ${err.message}`,
      tag: "load_checkout",
      level: Sentry.Severity.Error,
      shopifyStore,
      error: err,
    })
    return null
  }

  if (response?.data?.status !== "success") {
    console.error(`[Load Checkout][ERROR]: ${response.data.body}`)
    sendSentryEvent({
      message: `[Load Checkout][${shopifyOrderId}][ERROR]: ${response.data.body}`,
      tag: "load_checkout",
      level: Sentry.Severity.Error,
      shopifyStore,
      responsePayload: `${response.data}`,
    })
    return null
  }

  return response.data.body
}

export const loadPaymentConfigs = async shopifyStore => {
  const { sendSentryEvent } = useSentry()
  let response

  try {
    response = await axios.get(`${config.services.onedaypay.baseUrl}/api/payment-settings/`, {
      headers: { "x-shopify-shop-domain": `${shopifyStore}.myshopify.com` },
    })
  } catch (err) {
    console.error(`[Load Config][ERROR]: ${err.message}`)
    sendSentryEvent({
      message: `[Load Config][ERROR]: ${err.message}`,
      tag: "load_payment_config",
      level: Sentry.Severity.Error,
      shopifyStore,
      error: err,
    })
    return null
  }

  if (response?.data?.status !== "success") {
    console.error(`[Load Config][ERROR]: ${response.data.body}`)
    sendSentryEvent({
      message: `[Load Config][ERROR]: ${response.data.body}`,
      tag: "load_payment_config",
      level: Sentry.Severity.Error,
      shopifyStore,
      responsePayload: `${response.data}`,
    })
    return null
  }

  return response.data.body
}

export const sendContribution = async (shopifyCustomerId, shopifyOrderId, name, email, message, shopifyStore) => {
  const { sendSentryEvent } = useSentry()
  let response

  try {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/onedaypay/${shopifyOrderId}/charge?customer_id=${shopifyCustomerId}`
    response = await axios.get(`${config.services.onedaypay.baseUrl}/api/customers/${shopifyCustomerId}/payment-plans/${shopifyOrderId}/send`, {
      headers: { "x-shopify-shop-domain": `${shopifyStore}.myshopify.com` },
      params: {
        name,
        email,
        message,
        redirect_url: redirectUrl,
      },
    })
  } catch (err) {
    console.error(`[Send Contribution][ERROR]: ${err.message}`)
    sendSentryEvent({
      message: `[Send Contribution][ERROR]: ${err.message}`,
      tag: "send_contribution",
      level: Sentry.Severity.Error,
      shopifyStore,
      error: err,
    })
    return null
  }

  if (response?.data?.status !== "success") {
    console.error(`[Send Contribution][ERROR]: ${response.data.body}`)
    sendSentryEvent({
      message: `[Send Contribution][ERROR]: ${response.data.body}`,
      tag: "send_contribution",
      level: Sentry.Severity.Error,
      shopifyStore,
      responsePayload: `${response.data}`,
    })
    return null
  }

  return response.data.body
}
