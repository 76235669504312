import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useLanguage } from "../../../hooks/useLanguage"
import { useShopify } from "../../../hooks/useShopify"

export const withCartItemAttributes = Component => ({ name = `CartItemAttributes`, drawer, grouped, product }) => {
  const {
    config: { settings },
  } = useApp()
  const locales = useLanguage(`products`)
  const { formatTimeline } = useShopify()

  const timeline = formatTimeline(product.tags.find(tag => tag.includes(settings.products.delivery.timelineTag)))

  Component.displayName = name
  return <Component drawer={drawer} grouped={grouped} locales={locales} timeline={timeline} />
}
