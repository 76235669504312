import React from "react"

import { withHeader } from "./withHeader"
import { NavigationMain } from "../Navigation/Main/NavigationMain"
import { NavigationSearch } from "../Navigation/Search/NavigationSearch"
import { Brand } from "../Brand/Brand"
import { NavigationAccount } from "../Navigation/Account/NavigationAccount"
import { CartDrawer } from "../Cart/Drawer/CartDrawer"
import { Section, StyledContainer, StyledHeader } from "../Layout/LayoutStyles"

interface Props {
  activeCart: boolean
  activeMenu: boolean
  activeScrolled: boolean
  activeSearch: boolean
  activeSticky: boolean
  handleSearch: any
  header: any
  invertedTheme: boolean
  offsetHeight: boolean
  location: any
  routes: any
  search: any
  setActiveCart: any
  setActiveMenu: any
  organisation: any
  title?: string
  transparentTheme: boolean
}

export const Header = withHeader(
  ({
    activeCart,
    activeMenu,
    activeSticky,
    activeScrolled,
    activeSearch,
    handleSearch,
    header,
    invertedTheme,
    offsetHeight,
    location,
    routes,
    search,
    setActiveCart,
    setActiveMenu,
    organisation,
    title = null,
    transparentTheme,
  }: Props) => (
    <Section offset={offsetHeight}>
      <StyledHeader offset={offsetHeight} ref={header} visible={!activeSticky} inverted={transparentTheme || invertedTheme} scrolled={activeScrolled}>
        {location?.pathname !== routes.SEARCH && (
          <NavigationSearch
            activeSearch={activeSearch}
            setActiveSearch={handleSearch}
            inverted={transparentTheme || invertedTheme}
            location={location}
            reference={search}
          />
        )}

        <StyledContainer inverted={transparentTheme || invertedTheme} scrolled={activeScrolled}>
          <NavigationMain activeMenu={activeMenu} setActiveMenu={setActiveMenu} />

          <Brand title={title || organisation?.title} />

          <NavigationAccount
            activeCart={activeCart}
            setActiveCart={setActiveCart}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            activeSearch={activeSearch}
            setActiveSearch={handleSearch}
            location={location}
          />
        </StyledContainer>
      </StyledHeader>

      <CartDrawer activeCart={activeCart} setActiveCart={setActiveCart} />
    </Section>
  )
)
