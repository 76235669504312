import gql from "graphql-tag"

import { IMAGE_FRAGMENT } from "./imageFragment"

export const COLLECTION_FRAGMENT = gql`
  fragment CollectionFragment on Collection {
    id
    title
    handle
    description
    descriptionHtml
    image {
      ...ImageFragment
    }
  }
  ${IMAGE_FRAGMENT}
`