import React, { useCallback, useState } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import tw from "twin.macro"

import { useApp } from "./useApp"
import { useRoutes } from "./useRoutes"
export { SearchComponents } from "../providers/search"

import Close from "static/icons/theme/close.svg"
export const Icon = tw(Close)`block w-2-4 h-2-4`

// external sort state
type SortState = { dataField: string; sortBy: string }

// curation from reactify search config api
type Curation = {
  collectionHandle?: string
  searchTerm?: string
  type: string
  boosting: {
    groupings: {
      key: string
      field: string
      operation: string
      position: number
      value: string | number
      query: string // JSON encoded query component
    }[]
    sortings: {
      key: string
      field: string
      direction: "asc" | "desc"
      position: number
      query: string // JSON encoded query component
    }[]
  }
}

export const useSearch = (location = null) => {
  const {
    config: {
      settings: { params },
    },
  } = useApp()
  const { getUrlParameter, setUrlParameter } = useRoutes()
  const [query, setQuery] = useState(getUrlParameter(params.search, location) || ``)
  const [timer, setTimer] = useState()
  const [currentQuery, setCurrentQuery] = useState(query)

  const debounce = 800

  const handleChange = (value, triggerQuery, event) => {
    if (event) {
      setQuery(value)
      clearTimeout(timer)
    }

    // Set a timer for debouncing, if it's passed, call triggerQuery.
    setTimer(
      setTimeout(() => {
        setCurrentQuery(value)
        triggerQuery()
      }, debounce)
    )
  }

  const handleKey = (e, triggerQuery) => {
    if (e.key === "Enter") {
      clearTimeout(timer)
      setCurrentQuery(query)

      if (location?.pathname === "/search") {
        // Trigger instant search from the Search page
        triggerQuery()
        navigate(setUrlParameter(params.search, query, location?.pathname), { replace: true })
      } else if (query.length >= 3) {
        // Redirect to the Search page and trigger instant search
        const searchUrl = `/search?q=${query}`
        navigate(searchUrl)
      }
    }
  }

  const { search: settings } = useStaticQuery(graphql`
    query SEARCH_SETTINGS {
      search: allThirdPartySearch {
        edges {
          node {
            ...SearchFragment
          }
        }
      }
    }
    fragment SearchFragment on thirdParty__Search {
      filters {
        id: thirdParty_id
        name
        type
        defaultSort
        pageSize
        paginationType
        inventoryVisibility
        options {
          id: thirdParty_id
          name
          field
          valuesShow
          valuesManual
          displayType
          displayView
          displaySize
          displaySliderStep
          displaySliderInterval
          settingsUppercase
          settingsShowSearch
          settingsShowFilter
          settingsShowMore
          settingsCollapsedDesktop
          settingsCollapsedMobile
          position
        }
      }
      sort {
        name
        field
        direction
        visibility
        position
      }
      fields: thirdParty_fields {
        field
        importance
        searchType
      }
      curations {
        collectionHandle
        searchTerm
        type
        boosting {
          groupings {
            key
            field
            operation
            position
            value
            query
          }
          sortings {
            key
            direction
            field
            position
            query
          }
        }
      }
    }
  `)

  const { fields, filters, sort, ...rest } =
    settings?.edges?.map(({ node }) => ({
      ...node,
    }))[0] || false

  const curations = rest.curations as Curation[]

  const resolveCuration = useCallback(
    (type: "search" | "collection", handleOrSearchTerm: string): Curation | null => {
      const curation = curations.find(curation => {
        if ("search" === type) return "search" === curation.type && handleOrSearchTerm === curation.searchTerm
        if ("collection" === type) return "collection" === curation.type && handleOrSearchTerm === curation.collectionHandle
        return false
      })

      return curation ? curation : null
    },
    [curations]
  )

  // build the `sort` part of the query using external sort state and matching curation (if any)
  const buildSortQuery = useCallback(
    (type: "search" | "collection", handleOrSearchTerm: string, sortState: SortState): any[] => {
      // curation positions are only applied for the default `collections.position` sort
      // if the sort is something else, apply a normal sort which applies what the user has requested
      if ("collections.position" !== sortState.dataField && "_score" !== sortState.dataField) {
        return [{ [sortState.dataField]: sortState.sortBy }]
      }

      // find curation with matching collection handle or search term
      const curation = resolveCuration(type, handleOrSearchTerm)

      // no matching curation, return default sort order
      if (!curation) {
        return [{ [sortState.dataField]: sortState.sortBy }]
      }

      const sorts = []

      // show pins first
      sorts.push({
        "curations.position": {
          unmapped_type: "long",
          order: "asc",
          nested: {
            path: "curations",
            filter: {
              term: {
                [`curations.${"collection" === type ? "collectionHandle" : "searchTerm"}.keyword`]: handleOrSearchTerm,
              },
            },
          },
        },
      })

      if (0 < curation.boosting.groupings.length) {
        const groupings = curation.boosting.groupings.sort((a, b) => (a.position > b.position ? 1 : -1))

        for (const grouping of groupings) {
          try {
            const query = JSON.parse(grouping.query)
            if (!!query) sorts.push(query)
          } catch {
            console.error(`query could not be parsed for boost grouping`, grouping)
          }
        }
      }

      if (0 < curation.boosting.sortings.length) {
        const sortings = curation.boosting.sortings.sort((a, b) => (a.position > b.position ? 1 : -1))

        for (const sorting of sortings) {
          try {
            const query = JSON.parse(sorting.query)
            if (!!query) sorts.push(query)
          } catch {
            console.error(`query could not be parsed for boost sorting`, sorting)
          }
        }
      }

      // finally, sort by elastic score or index order
      // using index order ensures a consistent output order for queries which don't really
      // use a relevance score like collections
      if ("collection" === type) sorts.push("_doc")
      else sorts.push("_score")

      return sorts
    },
    [resolveCuration]
  )

  const getFilterListDefaults = ({ defaultQuery, showSearch = false }) => ({
    defaultQuery: () => ({
      query: {
        bool: {
          must: defaultQuery,
        },
      },
    }),
    showSearch,
  })

  const getFilterReact = (filterId, exclude) =>
    getFilter(filterId)
      ?.options?.filter(({ id }) => !exclude || exclude !== id)
      ?.map(({ id }) => id)

  const getFilter = filterId => filters.find(({ id }) => id === filterId)

  const getFilterSliderDefaults = ({ interval, step }) => ({
    stepValue: interval || 50,
    interval: step || 10,
    defaultValue: (min, max) => ({ start: min, end: Math.min(2000, max) }),
    rangeLabels: (min, max) => ({ start: `$${min}`, end: `$${max}` }),
    showHistogram: false,
  })

  const getValues = (prev, name, filter) => {
    const filterValues = prev[filter.title] || []
    const newArr = filterValues.slice()
    let val
    if (filter.type === "multi") {
      if (newArr.includes(name)) {
        newArr.splice(newArr.indexOf(name), 1)
        val = newArr
      } else {
        val = [...newArr, name]
      }
    } else {
      val = newArr.includes(name) ? [] : [name]
    }

    return {
      ...prev,
      [filter.title]: val,
    }
  }

  const getAggs = (filter, options) => {
    const size = 99
    if (filter.field.includes(".nested")) {
      const fieldName = filter.field.replace(".nested", "")
      const fieldPath = filter.field.split(".nested")[0]
      return {
        aggs: {
          [filter.field]: {
            nested: {
              path: fieldPath,
            },
            aggs: {
              [filter.field]: {
                terms: {
                  field: fieldName,
                  size,
                },
              },
            },
          },
        },
      }
    } else if (filter.field === "variants.option1.keyword") {
      const stockFilter = options?.readyToShip
        ? {
            aggs: {
              stock: {
                terms: {
                  field: "variants.inventory_quantity",
                },
                aggs: {
                  stock_value: {
                    sum: {
                      field: "variants.inventory_quantity",
                    },
                  },
                  size_bucket_filter: {
                    bucket_selector: {
                      buckets_path: {
                        stockValue: "stock_value",
                      },
                      script: "params.stockValue > 0",
                    },
                  },
                },
              },
            },
          }
        : {}

      return {
        aggs: {
          [filter.field]: {
            filter: {
              term: {
                "options.keyword": "Size",
              },
            },
            aggs: {
              variants: {
                nested: {
                  path: "variants",
                },
                aggs: {
                  only_size: {
                    terms: {
                      field: filter.field,
                      size,
                    },
                    ...stockFilter,
                  },
                },
              },
            },
          },
        },
      }
    } else {
      return {
        aggs: {
          [filter.field]: {
            terms: {
              field: filter.field,
              size,
            },
          },
        },
      }
    }
  }

  const getQuery = (value, filter, options) => {
    let sizeQuery = null
    if (filter.id === "price") {
      if (!value.length) {
        return {}
      }
      const val = value[0]
      let query
      if (val.includes("Under ")) {
        query = {
          lte: Number(val.replace("Under ", "").replace("$", "").replace(",", "")),
        }
      } else if (val.includes(" +")) {
        query = {
          gte: Number(val.replace(" +", "").replace("$", "").replace(",", "")),
        }
      } else {
        const [min, max] = val.split(" - ")
        query = {
          lte: Number(max.replace("$", "").replace(",", "")),
          gte: Number(min.replace("$", "").replace(",", "")),
        }
      }
      return {
        query: {
          bool: {
            filter: {
              range: {
                [filter.field]: query,
              },
            },
          },
        },
      }
    }

    if (filter.field === "variants.option1.keyword") {
      if (options?.readyToShip && value.length) {
        sizeQuery = {
          query: {
            nested: {
              path: "variants",
              query: {
                bool: {
                  must: [
                    {
                      range: {
                        "variants.inventory_quantity": { gte: 1 },
                      },
                    },
                    {
                      terms: {
                        "variants.option1.keyword": value,
                      },
                    },
                  ],
                },
              },
            },
          },
        }
      } else if (options?.readyToShip) {
        sizeQuery = {
          query: {
            nested: {
              path: "variants",
              query: {
                bool: {
                  must: [
                    {
                      range: {
                        "variants.inventory_quantity": { gte: 1 },
                      },
                    },
                  ],
                },
              },
            },
          },
        }
      } else if (value.length) {
        sizeQuery = {
          query: {
            nested: {
              path: "variants",
              query: { terms: { [filter.field]: value } },
            },
          },
        }
      } else {
        sizeQuery = {}
      }
    }

    if (filter.field === "variants.option1.keyword") {
      return sizeQuery
    }

    if (value.length && filter.field.includes("nested")) {
      return {
        query: {
          nested: {
            path: filter.field.split(".nested")[0],
            query: { terms: { [filter.field.replace(".nested", "")]: value } },
          },
        },
      }
    }

    if (value.length) {
      return {
        query: {
          terms: {
            [filter.field]: value,
          },
        },
      }
    }

    return {}
  }

  const getFiltersConfig = (filterId = "collection") => {
    return getFilter(filterId)?.options?.map(option => ({
      id: option.id,
      title: option.name,
      type: option.displayType,
      view: option.displayView,
      field: option.field,
      config: {
        componentId: option.id,
        filterLabel: option.name,
        dataField: option.field,
        react: {
          and: [...getFilterReact(filterId, option.id), "PublishedSensor", "HiddenSensor", "CollectionSensor"],
          or: ["q"],
        },
        size: 0,
        showFilter: option.settingsShowFilter || true,
        showLoadMore: option.settingsShowMore || false,
        ...(option.displayType === `multi` && {
          ...getFilterListDefaults({ showSearch: option.settingsShowSearch }),
        }),
        ...(option.displayType === `single` && {
          ...getFilterListDefaults({ showSearch: option.settingsShowSearch }),
        }),
        ...(option.displayType === `slider` && {
          ...getFilterSliderDefaults({
            interval: option.displaySliderInterval,
            step: option.displaySliderStep,
          }),
        }),
        URLParams: false,
      },
      settings: {
        valuesManual: option.valuesManual,
        valuesShow: option.valuesShow,
        collapsedDesktop: option.settingsCollapsedDesktop,
        collapsedMobile: option.settingsCollapsedMobile,
        uppercaseValues: option.settingsUppercase,
      },
    }))
  }

  const getSearchFormConfig = () => ({
    autosuggest: false,
    clearIcon: <Icon onClick={() => setQuery("")} />,
    componentId: "q",
    dataField: fields?.map(item => item?.field),
    debounce: debounce,
    placeholder: "What are you looking for?",
    fieldWeights: fields?.map(item => item?.importance),
    filterLabel: `Term`,
    fuzziness: 0,
    size: 0,
    highlight: false,
    highlightField: `title`,
    onKeyPress: handleKey,
    onChange: handleChange,
    queryFormat: `and`,
    showVoiceSearch: false,
    showIcon: false,
    showClear: true,
    URLParams: false,
    value: query,
    currentQuery: currentQuery,
  })

  const getSuggestionConfig = () => ({
    componentId: `suggestions`,
    dataField: `title`,
    loader: () => null,
    pagination: false,
    infiniteScroll: false,
    react: {
      and: ["q", `PublishedSensor`, "HiddenSensor"],
    },
    scrollOnChange: false,
    showResultStats: false,
    size: 4,
    URLParams: false,
  })

  const getResultsConfig = () => ({
    componentId: "page",
    dataField: "title.keyword",
    includeFields: [
      "tags_colour",
      "tags",
      "tags_style",
      "tags_fit",
      "tags_fabric",
      "title",
      "images",
      "vendor",
      "options",
      "collections",
      "price_min",
      "price_max",
      "handle",
      "id",
      "variants",
    ],
    size: 21,
    react: {
      and: [...getFilterReact("collection"), "CollectionSensor", "HiddenSensor", "PublishedSensor", "SortingSensor"],
    },
    pagination: getFilter("collection")?.paginationType !== "infinite_scroll",
    infiniteScroll: getFilter("collection")?.paginationType === "infinite_scroll",
    showResultStats: false,
    showLoader: false,
    scrollOnChange: false,
    URLParams: true,
  })

  const getSearchResultsConfig = () => ({
    componentId: "page",
    dataField: sort?.dataField || "title.keyword",
    sortBy: sort?.sortBy,
    size: 21,
    react: {
      and: ["CollectionSensor", "HiddenSensor", "PublishedSensor", "SortingSensor", "q"],
    },
    showResultStats: false,
    pagination: getFilter("search")?.paginationType !== "infinite_scroll",
    infiniteScroll: getFilter("search")?.paginationType === "infinite_scroll",
    scrollOnChange: false,
    URLParams: true,
  })

  const getDefaultSort = (type = "collection") => getFilter(type)?.defaultSort
  const getSortOptions = type =>
    sort
      ?.filter(({ visibility }) => visibility === `all` || visibility === type)
      .sort((a, b) => a.position - b.position)
      .map(item => ({
        label: item?.name,
        dataField: item?.field,
        sortBy: item?.direction,
      }))

  const getQueryString = () => {
    return query
  }

  return {
    buildSortQuery,
    getQueryString,
    getSearchFormConfig,
    getSuggestionConfig,
    getResultsConfig,
    getSearchResultsConfig,
    getFiltersConfig,
    getSortOptions,
    getDefaultSort,
    getFilterReact,
    getQuery,
    getValues,
    getAggs,
  }
}
