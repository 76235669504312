import React from "react"
import { Global as Globals, css } from "@emotion/core"
import tw from "twin.macro"

import { withLayoutGlobal } from "./withLayoutGlobal"
import { Styles } from "../../Styled/Text"

export const LayoutGlobal = withLayoutGlobal(({ invertedTheme }) => (
  <Globals
    styles={css`
      html {
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -ms-overflow-style: scrollbar;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        ${tw`font-body text-base`}
        ${invertedTheme ? tw`bg-dark text-light` : tw`bg-light text-dark`}
      }

      html,
      body,
      #___gatsby,
      div[role="group"][tabindex] {
        ${tw`h-full`}
      }
      html {
        &.is-hidden body {
          ${tw`overflow-hidden`}
        }
      }

      div[class*="-Base"] {
        ${tw`text-dark`}
      }

      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }

      #gorgias-web-messenger-container {
        ${tw`z-10`}
      }
      #gatsby-focus-wrapper {
        ${tw`flex flex-col`}
      }

      .rte {
        ${tw`whitespace-normal break-words`}
        p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          ol,
          ul,
          blockquote {
          ${tw`mb-1-6`}
        }
        ol,
        ul,
        li {
          ${tw`ml-1-2`}
        }
        p {
          strong,
          b {
            ${tw`font-medium`}
          }
          em,
          i {
            ${tw`italic`}
          }
        }
        h1 {
          ${Styles.H1}
        }
        h2 {
          ${Styles.H2}
        }
        h3 {
          ${Styles.H3}
        }
        h4 {
          ${Styles.H4}
        }
        h5 {
          ${Styles.H5}
        }
        h6 {
          ${Styles.Label}
        }
        blockquote {
          ${Styles.P}
          ${tw`border-l pl-1-2 border-grey-mid italic`}
        }
        ol {
          ${tw`list-decimal`}
        }
        ul {
          ${tw`list-disc `}
        }
        a {
          ${tw`underline hover:no-underline`}
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .rte {
        em {
          ${tw`font-sans font-normal`}
        }
        strong {
          ${tw`font-display font-normal not-italic`}
        }
      }

      .glider,
      .glider-contain {
        ${tw`relative mx-auto my-0`}
      }
      .glider,
      .glider-track {
        transform: translateZ(0);
      }
      .glider-dot,
      .glider-next,
      .glider-prev {
        ${tw`select-none outline-none`}
      }
      .glider-contain {
        ${tw`w-full`}
      }
      .glider {
        ${tw`overflow-y-hidden scrolling-touch`}/* -ms-overflow-style: none; */
      }
      .glider-track {
        ${tw`flex w-full z-1`}
      }
      .glider.draggable {
        ${tw`select-none cursor-grab`}/* cursor: -webkit-grab; */
      }
      .glider.draggable .glider-slide img {
        ${tw`select-none pointer-events-none`}
      }
      .glider.drag {
        ${tw`select-none cursor-grabbing`}/* cursor: -webkit-grabbing; */
      }
      .glider-slide {
        ${tw`w-full content-center justify-center select-none inset-auto`}
      }
      .glider-slide img {
        ${tw`max-w-full`}
      }
      .glider::-webkit-scrollbar {
        ${tw`opacity-100 appearance-none w-2 h-1 md:opacity-0 md:h-0`}
      }
      .glider::-webkit-scrollbar-thumb {
        ${tw`opacity-100 md:opacity-0`}
      }
      .glider-next,
      .glider-prev {
        ${tw`absolute top-30-p hidden md:flex no-underline cursor-pointer text-3xl opacity-100 leading-normal z-10`}
      }
      .glider-next:focus,
      .glider-next:hover,
      .glider-prev:focus,
      .glider-prev:hover {
        ${tw`opacity-30`}
      }
      .glider-prev {
        ${tw`left-0 -ml-4`}
      }
      .glider-next {
        ${tw`right-0 -mr-4`}
      }
      .glider-next.disabled,
      .glider-prev.disabled {
        ${tw`opacity-30 cursor-default`}
      }
      .glider-hide {
        ${tw`opacity-0`}
      }
      .glider-dots {
        ${tw`absolute flex bottom-2-2 inset-x-0 justify-center select-none`}
      }
      .glider-dot {
        ${tw`relative block w-4 py-1 mx-1 cursor-pointer opacity-50 focus:outline-none transition-all duration-200`}
      }
      .glider-dot:after {
        content: "";
        ${tw`absolute block inset-x-0 top-1/2 w-full h-0-1 bg-light`}
      }
      .glider-dot:focus,
      .glider-dot:hover,
      .glider-dot.active {
        ${tw`opacity-100`}
      }
    `}
  />
))
