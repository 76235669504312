import React from "react"

import { useMeta } from "../../hooks/useMeta"
import { useSanity } from "../../hooks/useSanity"

export const withMeta = Component => ({ name = "Meta", breadcrumbs = [], data, routes, url }) => {
  const { getData, getLanguages, getSchemas, getTags, getTracking } = useMeta()
  const { richTextNormaliser, textNormaliser } = useSanity()

  const languages = getLanguages(url)

  const formattedData =
    data?.page?.title?._type === "localeBlockContent"
      ? { ...data, page: { ...data.page, title: richTextNormaliser(data?.page?.title) } }
      : data?.page?.title?._type === "localeString"
      ? { ...data, page: { ...data.page, title: textNormaliser(data?.page?.title) } }
      : data

  const metadata = getData({
    data: formattedData,
    breadcrumbs,
    language: languages?.find(({ primary }) => primary)?.language,
    routes,
    template: data?.template?.metadata,
    url,
  })

  const metatags = getTags(metadata)
  const schemas = getSchemas(metadata)
  const tracking = getTracking()

  Component.displayName = name
  return (
    <Component languages={languages} metatags={metatags} metadata={metadata} schemas={schemas} tracking={tracking}>
      {tracking.map(script => script)}
    </Component>
  )
}
