import styled from "@emotion/styled"
import tw from "twin.macro"

import Cart from "static/icons/theme/bag.svg"
import Plus from "static/icons/theme/plus.svg"
import Minus from "static/icons/theme/minus.svg"
import Inactive from "static/icons/theme/wishlist-inactive.svg"

import { Container } from "../Styled/Container"
import { P, H5 } from "../Styled/Text"
import { Input } from "../Styled/Input"
import { Textarea } from "../Styled/Textarea"
import { SmallButton, TextButton, PrimaryButton } from "../Styled/Button"

export const CartPage = tw.div`pb-12`
export const StyledContainer = styled(Container)`
  ${tw`max-w-lg py-5 md:pt-12-3 pb-9-6 md:flex justify-between`} ${props => (props.count ? tw`items-start ` : ``)}
`
export const LineItems = styled.section`
  ${tw`w-full md:w-7/10 flex-1 md:border-r border-grey md:pr-3-2`}
  ${props => (!props.count ? tw`flex flex-col py-3-2 items-center justify-center` : ``)}
`
export const CartRow = tw.section`md:flex items-start justify-between`
export const Details = tw.section`w-full md:w-3/10 md:pl-1-6 mt-3-2 md:mt-0 border-t border-grey md:border-t-0 pt-9-6 md:pt-0`
export const StyledH5 = tw(H5)`mb-2-4`

export const CartCounter = tw.div`flex items-center justify-center focus:outline-none`
export const IconCart = tw(Cart)`block w-2 h-2`
export const CartItems = tw.span`text-xxs font-medium w-1`

export const Wrapper = tw.div`w-full md:w-1/2`
export const Form = tw.form`border-t border-grey md:border-t-0 mt-2-4 md:mt-0 pt-2-4 md:pt-0 w-full flex flex-col md:flex-row items-center justify-center md:justify-end`
export const StyledP = tw(P)`flex-1`
export const StyledInput = tw(Input)`md:flex-1 mx-0-8 h-3-2 mt-1-2 md:mt-0`
export const StyledSmallButton = styled(SmallButton)`
  ${tw`w-full mt-1-2`}
  ${props => (!props.drawer ? tw`md:flex-1 md:w-auto md:mt-0` : ``)}
`

export const Counter = tw.div`absolute top-0 left-0 ml-1-6 mt-1-6`
export const DrawerWrapper = styled.div`
  ${tw`absolute flex flex-col items-center inset-x-0 bottom-0 top-4`} ${props => (props.count ? tw`justify-start` : tw`justify-center px-5-2`)}
`
export const DrawerLineItems = tw.div`w-full mt-2 mb-0-4 px-1-6 overflow-y-auto overflow-x-hidden flex-1`
export const Footer = tw.div`w-full py-1-2 px-1-6 border-t border-grey`
export const Continue = tw.div`block w-full mt-0-7 text-center py-0-9`
export const StyledTextButton = tw(TextButton)`text-grey-mid hover:text-dark`

export const WrappingButton = tw(TextButton)`pt-2-4 md:pt-0 w-full md:w-1/2 flex flex-col md:flex-row items-center justify-start hover:no-underline`
export const IconPlus = tw(Plus)`block w-1-9 h-1-9 md:mr-0-4 mb-0-8 md:mb-0`
export const Underline = tw(P)`underline ml-1`
export const Content = tw.div`text-center w-full max-w-57-7 mx-auto pt-2-4 md:pb-3-2`
export const WrappingForm = tw.div`w-full max-w-39-2 mx-auto flex flex-col items-center`
export const WrappingPrimaryButton = tw(PrimaryButton)`mt-1`
export const WrappingH5 = tw(H5)`mb-0-8`
export const WrappingP = tw(P)`mb-1-8`
export const WrappingTextarea = styled(Textarea)`
  ${tw`mt-0-8`} ${props => (props.hidden ? tw`hidden` : `block`)}
`

export const Mobile = tw.div`block md:hidden`
export const LineItem = styled.div`
  ${tw`justify-between mb-1-6`}
  ${props => (props.drawer ? tw`flex items-start` : tw`block md:flex border-b border-grey pb-1-6`)} ${props =>
    props.removing ? tw`opacity-50 pointer-events-none` : ``}
`
export const ImageWrapper = styled.div`
  ${props => (props.drawer ? tw`w-1/2 pr-1-6` : tw`w-full md:w-1/4 md:pr-1-6 mb-1-2 md:mb-0`)}
`
export const RatioWrapper = tw.div`block relative`
export const ImageRatio = styled.div`
  ${tw`block pt-150-p`} ${props => (props.screenWidth ? tw`w-9/10-vw md:w-full` : ``)}
`
export const PinnedImage = tw.div`absolute inset-0`
export const LineItemContent = styled.div`
  ${tw`w-full md:w-1/2`} ${props => (!props.drawer ? tw`flex flex-col flex-1` : ``)}
`
export const Title = tw(P)`block`
export const Pricing = tw.div`flex items-start justify-end md:justify-between w-1/4`
export const Price = tw(P)`hidden md:block`
export const Info = tw.div`w-full flex-1`
export const LineItemTitle = styled.div`
  w-fullmd: w-1/2;
`
export const Attribute = styled(P)`
  ${props => (props.drawer ? `` : tw`text-grey-mid`)}
`
export const Actions = tw.div`flex items-center justify-start w-full`
export const Divider = tw(P)`mr-1 text-base`
export const LineItemDetails = styled.div`
  ${props => (props.drawer ? tw`mb-1-2` : tw`flex flex-wrap items-start justify-between mb-1-6`)}
`
export const LineItemTextButton = styled(TextButton)`
  ${tw`text-xs text-left px-0 tracking-relaxed`}
  ${props => (props.drawer ? tw`leading-loosest underline hover:text-grey-darkest` : tw`flex justify-start items-center py-0 leading-normal mr-1`)}
`
export const IconInactive = tw(Inactive)`block w-2-2 h-2-2 mr-0-4 text-grey-darker`
export const Caps = tw.span`capitalize`
export const InformationContent = tw.div`text-sm mt-1-2`

export const Quantity = tw.div`max-w-10 border border-grey`
export const QuantityRow = tw.div`px-0-4 py-0-6 -mx-0-2 flex items-center justify-between`
export const QuantityAmount = tw(P)`text-center mt-0-2 px-0-4 truncate`
export const QuantityButton = tw(TextButton)`flex items-center justify-center px-0-4 py-0`
export const QuantityMinus = tw(Minus)`block w-1-9`
export const QuantityPlus = tw(Plus)`block w-1-9`

export const Row = styled.div`
  ${tw`flex items-center justify-between`}
  ${props => (props.spacing ? tw`mb-1-6` : tw`mb-0-2`)}
  ${props => (props.condensed ? tw`-mx-0-4` : tw`md:-mx-1-6`)}
`
export const Col = styled(P)`
  ${tw`w-1/2`}
  ${props => (props.right ? tw`text-right` : tw`text-left`)}
  ${props => (props.condensed ? tw`px-0-4` : tw`md:px-1-6`)}
`
export const Note = tw(P)`text-grey-mid mb-2-4`

export const PaymentLabel = styled(P)`
  ${tw`text-dark mb-2-4`}
  ${props => (props.condensed ? tw`text-xs` : tw`text-sm`)}
`
export const PaymentLabelLink = tw.span`underline cursor-pointer`

export const PaymentOptionLabel = tw(P)`mb-1-2 text-xs text-grey-darker`
export const PaymentOptionLinkLabel = tw.span`underline cursor-pointer text-dark hover:no-underline`
