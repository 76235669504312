import React from "react"

import { AppProvider } from "./app"
import { CheckoutProvider } from "./checkout"
import { CustomerProvider } from "./customer"
import { FirebaseProvider } from "./firebase"
import { GlobalProvider } from "./global"
import { LocationProvider } from "./location"
import { ShopProvider } from "./shop"
import { ShopifyProvider } from "./shopify"
import { SearchProvider } from "./search"
import { TrackingProvider } from "./tracking"
import { WishlistProvider } from "./wishlist"

import config from "../../config.default"

export const Providers = ({ children }) => (
  <GlobalProvider>
    <LocationProvider config={config}>
      <ShopifyProvider
        config={{
          shopName: config?.services?.shopify?.defaultShopName,
          apiVersion: config?.services?.shopify?.apiVersion,
          accessToken: config?.stores[config?.services?.shopify?.defaultShopName]?.accessToken,
        }}
        allStores={config.stores}
      >
        <FirebaseProvider config={config?.services?.firebase}>
          <AppProvider config={config}>
            <ShopProvider>
              <CustomerProvider>
                <CheckoutProvider>
                  <WishlistProvider>
                    <TrackingProvider>
                      <SearchProvider
                        config={{
                          ...config?.services?.reactify,
                          searchIndex: config?.stores[config?.services?.shopify?.defaultShopName]?.searchIndex,
                        }}
                      >
                        {children}
                      </SearchProvider>
                    </TrackingProvider>
                  </WishlistProvider>
                </CheckoutProvider>
              </CustomerProvider>
            </ShopProvider>
          </AppProvider>
        </FirebaseProvider>
      </ShopifyProvider>
    </LocationProvider>
  </GlobalProvider>
)
