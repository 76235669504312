import tw from "twin.macro"
import styled from "@emotion/styled"

import { PrimaryButton } from "../../Styled/Button"
import { TextButton } from "../../Styled/Button"
import { Label, P } from "../../Styled/Text"
import { Column } from "../../Styled/Table"

export const StyledTextButton = tw(TextButton)`absolute inset-0 px-0-4 flex items-center justify-start whitespace-no-wrap hover:no-underline`
export const Placeholder = tw.div`w-2/3 py-0-9 my-0-1 bg-grey-lightest animate-placeholder`
export const StyledContentBlock = tw.div`mt-3-2 mb-5 text-center bg-grey py-2 relative`
export const Message = tw(P)`font-medium mb-1-8 text-xs`
export const StyledP = tw(P)`font-medium mb-1-8`
export const StyledSpan = tw.span`text-grey-darker`
export const StyledHeading = styled(P)`
  ${tw`font-sans leading-relax my-3-2`}
  ${props => (props.noConvertCase ? `` : tw`uppercase`)}
`
export const StyledLink = tw(P)`underline`
export const PaymentImg = tw.img`h-2 mr-1`
export const StyledColumn = tw(Column)`text-grey-darker`
export const Buttons = tw.div`mt-4 mb-1 flex items-center justify-between -mx-0-6`
export const Button = tw.div`w-1/2 px-0-6`
export const StyledPrimaryButton = tw(PrimaryButton)`px-0`
export const TimelineLabel = tw(P)`text-xs tracking-relaxed leading-long`
export const TimelineButton = styled(TimelineLabel)`
  ${tw`underline ml-0-6 hover:no-underline focus:outline-none`}
  ${props => (props.light ? tw`text-grey-darker` : ``)}
`
export const Content = tw.div`text-left w-full max-w-40 mx-auto pt-2-4 md:pb-3-2`
export const Row = tw.div`flex items-start justify-start mb-1`
export const Col = styled.div`
  ${tw`relative text-sm`}
  ${props => (props.condensed ? tw`w-1/3` : tw`w-1/2`)}
`
export const ItemTitle = tw(P)`ml-1`
export const ItemOption = tw(P)`text-grey-darker ml-1`

export const StyledTag = styled.div`
  ${tw`py-0-2 px-1 w-full mx-1 text-center border rounded-0-8`}
  ${props => (props.status === "Active" ? tw`bg-dark text-light border-dark` : ``)}
  ${props => (props.status === "Completed" ? tw`bg-light text-grey-darker border-grey-darker` : ``)}
  ${props => (props.status === "Cancelled" ? tw`bg-grey-darker text-light border-grey-darker` : ``)}
`

export const StyledTermsContainer = tw.div`text-left w-full p-2-4 md:p-3-2`
export const StyledPromotionalText = tw(P)`font-medium mb-1-8 underline cursor-pointer`

export const StyledLoadingContainer = tw.div`w-full max-w-39-2 mx-auto my-4 flex justify-center`

export const PaymentMethodP = tw(P)`w-full my-0-8 tracking-normal text-grey-darker`
export const PaymentMethodLabel = tw(Label)`mb-0-8`
export const PaymentMethodEmpty = tw(P)`text-center p-0-6 w-full`
export const PaymentMethods = tw.div`flex flex-wrap -m-0-6 mb-4`
export const PaymentMethod = tw.li`block w-full md:w-1/2 p-0-6`
export const PaymentMethodContent = styled.div`
  ${tw`flex flex-col justify-between p-1-6 pb-0-9 border text-left h-full transition-all duration-200`}
  ${props => (props.selected ? tw`border-grey-darkest` : tw`border-grey`)}
`
export const PaymentMethodDetails = tw.div`pb-4-8`
export const PaymentMethodLinks = tw.div`flex items-end`
export const PaymentMethodButton = tw(TextButton)`flex items-center justify-start mr-2-4 hover:no-underline hover:text-grey-darkest`
export const PaymentMethodFormFluidField = styled.div`
  ${tw`w-full`}
  ${props => (props.width === `1/2` ? tw`md:w-1/2` : ``)}
`
