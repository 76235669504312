import gql from "graphql-tag"

export const PRICE_FRAGMENT = gql`
  fragment PriceFragment on ProductVariant {
    priceV2 {
      amount
      currencyCode
    }
    compareAtPriceV2 {
      amount
      currencyCode
    }
  }
`