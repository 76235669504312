import React, { createContext, useRef, useState, useEffect, useCallback } from "react"

import { useCore } from "../hooks/useCore"
import { useGlobal } from "../hooks/useGlobal"

export const LocationContext = createContext(null)

export const LocationProvider = ({ children, config }) => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()
  const { handleGlobalGroupReady } = useGlobal()
  const { keys } = config?.settings
  const { location: locationService } = config?.services
  const defaultStore = config?.services?.shopify?.defaultShopName
  const defaultStoreDomain = config?.stores[defaultStore].shopDomain
  const shopifyStore = storage.get(keys?.shopify) || defaultStore
  const shopifyStoreDomain = config?.stores[shopifyStore]?.shopDomain || defaultStoreDomain
  const siteCountries = config?.stores[shopifyStore].siteCountries
  const siteCurrency = config?.stores[shopifyStore].siteCurrency
  const [currencyCode, setCurrencyCode] = useState(config?.stores[shopifyStore].siteCurrency)


  const [settings, setSettings] = useState({
    country: storage.get(keys?.country) || "",
    countryCode: storage.get(keys?.location) || "",
    currencyCode: storage.get(keys?.location) === "US" ? "USD" : "AUD",
    location: storage.get(keys?.location) || "",
    locations: Object.values(config?.stores).map(store => store?.siteLocation),
    locating: !storage.get(keys?.country)?.length,
    storeConfig: config?.stores[shopifyStore] || config?.stores[defaultStore],
    visitor: !config?.stores[shopifyStore]?.siteCountries?.includes(storage.get(keys?.country) || ""),
    shopifyStore,
    shopifyStoreDomain,
  })
  const prevCountryCode = useRef(settings?.country);

  const updateLocation = useCallback(
    async (countryCode, updateCountry = false) => {
      const shopifyStoreDomain =
        Object.values(config?.stores).find(store => store?.siteCountries.includes(countryCode))?.shopDomain || defaultStoreDomain
      const shopifyStore =
        Object.values(config?.stores).find(store => store?.siteCountries.includes(countryCode))?.shopName || defaultStore

      setSettings(prevState => ({
        ...prevState,
        country: updateCountry ? countryCode : settings?.country,
        countryCode: countryCode,
        currencyCode: countryCode === "US" ? "USD" : "AUD",
        location: countryCode,
        shopifyStore: shopifyStore,
        shopifyStoreDomain: shopifyStoreDomain,
        storeConfig: config?.stores[shopifyStore],
        visitor: locationService?.forcelocation ? !config?.stores[shopifyStore]?.siteCountries?.includes(settings?.country) : false,
        locating: false,
      }))

      if (prevCountryCode?.current !== countryCode) {
        storage.set(keys?.customer, "")
        if (isBrowser) {
          window.location.reload()
        }
      }
    },
    []
  )

  useEffect(() => {
    fetch(locationService?.serviceUrl)
      .then(res => res.json())
      .then(result => {
        if (result[locationService?.countryFieldKey] !== siteCountries) {
          updateLocation(result[locationService?.countryFieldKey] || config?.stores[defaultStore]?.siteLocation, true)
        }
      }
      )
      .catch(() => updateLocation(config?.stores[defaultStore]?.siteLocation, true))

    handleGlobalGroupReady("location")
  }, [])

  useEffect(() => {
    if (settings?.country && settings?.shopifyStore && settings?.location) {
      storage.set(keys?.country, settings?.country)
      storage.set(keys?.location, settings?.location)
      storage.set(keys?.shopify, settings?.shopifyStore)
    } else {
      fetch(locationService?.serviceUrl)
        .then(res => res.json())
        .then(result => updateLocation(result[locationService?.countryFieldKey] || config?.stores[defaultStore]?.siteLocation, true))
        .catch(() => updateLocation(config?.stores[defaultStore]?.siteLocation, true))
    }
  }, [settings])

  return <LocationContext.Provider value={{ updateLocation, ...settings }}>{children}</LocationContext.Provider>
}

export const withLocation = Component => props => (
  <LocationContext.Consumer>
    {({ updateLocation, ...settings }) => <Component {...props} {...settings} updateLocation={updateLocation} />}
  </LocationContext.Consumer>
)
